<template>
  <div>
    <Modal :options="{close: true}" @close="$emit('close')">
      <div class="title">Fachada</div>
      <div class="body text-center">
        <img :src="'https://servicios.bienparabien.com/api/ws/documento/'+imagen+'/G1qMRi3LgEzsLyQTxUxkaZzCs6qPumv21hLLHaai'" alt="Fachada">
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  },
  props: {
    imagen: {
      type: String,
      required: true
    }
  }
}
</script>