<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-lg-2">
        <ul class="nav nav-pills flex-column mt-3">
          <li class="nav-item">
            <a href="#" :class="'nav-link'+(tab_selected == 'sla' ? ' active' : '')" @click.prevent="tab_selected='sla'">Sla's</a>
          </li>
          <li class="nav-item">
            <a href="#" :class="'nav-link'+(tab_selected == 'observaciones' ? ' active' : '')" @click.prevent="tab_selected='observaciones'">Observaciones</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-10">
        <Sla v-if="tab_selected == 'sla'" :solicitud="solicitud" />
        <Observacion v-if="tab_selected == 'observaciones'" :solicitud="solicitud" />
      </div>
    </div>
  </div>
</template>

<script>
import Sla from './Sla';
import Observacion from './Observacion';

export default {
  components: {
    Sla, Observacion
  },
  props: {
    solicitud: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab_selected: 'sla'
    }
  }
}
</script>