import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_COFINANCIAMIENTO+'/solicitudes');

export default {
  obtener_solicitudes() {
    return http.get(`/?relations[0]=alianza`);
  },

  obtener_solicitud(id) {
    return http.get(`/${id}`);
  },

  procesar_solicitud(id) {
    return http.get(`/procesar/${id}`);
  }
}