<template>
  <div>
    <div class="row">
      <h3 class="col-sm-12">Consulta Buró</h3>
    </div>

    <div v-for="buro in solicitud.data.creditos.permiso_buro_credito" class="row mb-4">
      <div class="col-sm-12 buro_titulo">#{{ solicitud.solicitud_id }}-{{ buro.id }}: {{ buro.nombre+' '+buro.paterno+' '+buro.materno }}</div>
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Propia:</div>
          <div class="content">{{ buro.autoriza_consulta_buro == 1 ? 'Sí' : 'No' }}</div>
        </div>
        <div class="item">
          <div class="title">Tipo persona:</div>
          <div class="content">{{ buro.tipo_persona }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="item">
          <div class="title">RFC:</div>
          <div class="content">{{ buro.rfc }}</div>
        </div>
        <div class="item">
          <div class="title">Fecha de consulta:</div>
          <div class="content">{{ buro.fecha_consulta_buro }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .buro_titulo {
    font-weight: bold;
    padding: 10px 15px;
  }

  .item {
    margin-bottom: 2px;
    border: solid 1px hsl(0,0%, 90%);

    .title, .content {
      padding: 7px 10px;
      display: inline-block;
    }
    .title {
      width: 170px;
      background-color: hsl(0, 0%, 90%);
    }
  }
</style>