<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-lg-2">
        <ul class="nav flex-column nav-pills mt-4">
          <li class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'cliente' ? ' active' : '')" @click="tab_active = 'cliente'">Cliente</a></li>
          <li class="nav-item dropdown">
            <a href="#" :class="'nav-link dropdown-toggle'+(tab_active == 'garantias' ? ' active' : '')" data-toggle="dropdown" role="button" aria-expanded="false">Garantias</a>
            <div class="dropdown-menu">
              <a v-for="garantia in solicitud.data.creditos.garantias_originacion" href="#" class="dropdown-item" @click.prevent="seleccionar_garantia(garantia)">{{ garantia.calle }}</a>
            </div>
          </li>
          <li class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'buro' ? ' active' : '')" @click="tab_active = 'buro'">Consulta buró</a></li>
          <!-- <li class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'areas' ? ' active' : '')" @click="tab_active = 'areas'">Áreas</a></li>
          <li class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'historial' ? ' active' : '')" @click="tab_active = 'historial'">Historial</a></li> -->
        </ul>
      </div>
      <div class="col-sm-12 col-lg-10 pt-4">
        <Cliente v-if="tab_active=='cliente'" :solicitud="solicitud" />
        <Garantia v-if="tab_active=='garantias'" :garantia="garantia" :solicitud="solicitud" />
        <Buro v-if="tab_active=='buro'" :solicitud="solicitud" />
        <Area v-if="tab_active=='areas'" :solicitud="solicitud" />
      </div>
    </div>
  </div>
</template>

<script>
import Cliente from './Cliente'
import Garantia from './Garantia'
import Buro from './Buro'
import Area from './Area'

export default {
  components: {
    Cliente, Garantia, Buro, Area
  },
  props: {
    solicitud: {
      type: Object,
      required: true,
      default() {
        return {
          solicitud_id: null
        }
      }
    }
  },
  data() {
    return {
      tab_active: 'cliente',
      garantia: null
    }
  },
  methods: {
    seleccionar_garantia(garantia) {
      this.garantia = garantia;
      this.tab_active = 'garantias';
    }
  }
}
</script>