<template>
  <div>
    <div class="row my-3">
      <h3 class="col-sm-3">Sla's</h3>
      <div class="col-sm-3 offset-sm-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Tipo de sla</span>
          </div>
          <select v-model="tipo_sla" name="tipo_sla" id="tipo_sla" class="form-control">
            <option value="3">Todos</option>
            <option value="1">Valuador</option>
            <option value="2">Notario</option>
            <option value="0">Sistema</option>
          </select>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Estatus de sla</span>
          </div>
          <select v-model="estatus_sla" name="estatus" id="estatus" class="form-control">
            <option value="0">Todos</option>
            <option value="1">Activos</option>
            <option value="2">Inactivo</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="sla in sla_estatus" class="col-sm-4 mb-3">
        <div class="card">
          <div class="card-body">
            <h5 :class="'card-title'+(sla.estatus == 1 ? ' bg-success text-white' : ' bg-danger')">Sla: {{ sla.tipo }}</h5>
            <div class="card-text">
              <ul class="sla_bloque">
                <li><span class="titulo">Estatus:</span> {{ sla.estatus == 1 ? 'Activo' : 'Inactivo' }}</li>
                <li><span class="titulo">Creación:</span> {{ $moment(sla.created_at).format('ddd DD MMM YYYY HH:mm') }}</li>
                <li v-if="sla.razon_cancelacion"><span class="titulo">Razón cancelación:</span> {{ sla.razon_cancelacion }}</li>
                <li v-if="sla.cancel_at"><span class="titulo">Fecha cancelación: {{ $moment(sla.cancel_at).format('ddd DD MMM YYYY HH:mm') }}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tipo_sla: 3,
      estatus_sla: 0
    }
  },
  computed: {
    sla_tipo() {
      let slas_catch = [];

      if (this.tipo_sla == 3)
        return this.solicitud.data.formalizacion.sla;

      this.solicitud.data.formalizacion.sla.forEach(sla => {
        if(sla.tipo_usuario == this.tipo_sla)
          slas_catch.push(sla)
      });

      return slas_catch;
    },
    sla_estatus() {
      let slas_catch = [];

      if (this.estatus_sla == 0)
        return this.sla_tipo;

      this.sla_tipo.forEach(sla => {
        if (sla.estatus == this.estatus_sla)
          slas_catch.push(sla);
      });

      return slas_catch;
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  .card-body {
    overflow: hidden;

    .card-title {
      margin: -20px;
      margin-bottom: 20px;
      padding: 20px;
      padding-bottom: 20px;
    }

    .sla_bloque {
      list-style: none;
      padding: 0;

      li {
        background-color: hsl(0, 0%, 97%);
        margin-bottom: 5px;
        .titulo {
          background-color: hsl(0, 0%, 90%);
          width: 100px;
          padding: 5px 13px;
          display: inline-block;
        }
      }
    }
  }
}
</style>