<template>
  <div>
    <div class="row mb-3">
      <h2 class="col-sm-12 text-center">Persona física</h2>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 col-xl-4">
        <ul>
          <li><span>Score de buró:</span><span> {{ parseFloat(buro.score).toFixed(0) }}</span></li>
          <li><span>Cuentas abiertas:</span><span>{{ buro.cuentas_abiertas }}</span></li>
          <li><span>Cuentas MOP 00:</span><span>{{ parseFloat(buro.cuentas_mop_00).toFixed(0) }}</span></li>
          <li><span>Cuentas atrasos menores:</span><span>{{ buro.total_cuentas_atrasos_menores }}</span></li>
          <li><span>Total créditos máximos pagos fijos:</span><span>${{ $helper.moneyFormat(buro.total_creditos_maximos_pagos_fijos) }}</span></li>
          <li><span>Total saldos vencidos pagos fijos:</span><span>${{ $helper.moneyFormat(buro.total_saldos_actuales_pagos_fijos) }}</span></li>
          <li><span>Total créditos máximos revolventes:</span><span>${{ $helper.moneyFormat(buro.total_saldos_vencidos_pagos_fijos) }}</span></li>
          <li><span>Total saldos actuales créditos revolventes:</span><span>${{ $helper.moneyFormat(buro.total_creditos_maximos_revolventes) }}</span></li>
          <li><span>Total saldos vencidos créditos revolventes:</span><span>${{ $helper.moneyFormat(buro.total_saldos_actuales_revolventes) }}</span></li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-6 col-xl-4">
        <ul>
          <li><span>Cuentas cerradas:</span><span>{{ buro.cuentas_cerradas }}</span></li>
          <li><span>Total solicitudes reporte:</span><span>{{ buro.total_solicitudes_reporte }}</span></li>
          <li><span>Cuentas negativas actuales:</span><span>{{ buro.cuentas_negativas_actuales }}</span></li>
          <li><span>Cuentas claves historia negativa:</span><span>{{ buro.cuentas_claves_historia_negativa }}</span></li>
          <li><span>Cuentas disputadas:</span><span>{{ buro.cuentas_disputa }}</span></li>
          <li><span>Existencia declaraciones consumidor:</span><span>{{ buro.existencia_declaraciones_consumidor }}</span></li>
          <li><span>Cuentas pagos fijos hipotecas:</span><span>{{ buro.cuentas_pagos_fijos_hipotecas }}</span></li>
          <li><span>Cuentas revolventes abiertas:</span><span>{{ buro.cuentas_revolventes_abiertas }}</span></li>
          <li><span>Fecha de ingreso DB:</span><span>{{ buro.fecha_ingreso_bd }}</span></li>
          <li><span>Fecha apertura de cuenta más antigua: </span><span>{{ buro.fecha_apertura_cuenta_mas_antigua }}</span></li>
          <li><span>Fecha apertura de cuenta más reciente: </span><span>{{ buro.fecha_apertura_cuenta_mas_reciente }}</span></li>
          <li><span>Nueva dirección: </span><span>{{ buro.nueva_direccion_reportada_ultimos_60_dias }}</span></li>
          <li><span>Fecha solicitud reporte más reciente: </span><span>{{ buro.fecha_solicitud_reporte_mas_reciente }}</span></li>
        </ul>
      </div>
      <div class="col-sm-12 col-xl-4">
        <ul>
          <li><span>Cuentas MOP 01:</span><span>{{ buro.total_cuentas_mop_01 }}</span></li>
          <li><span>Cuentas MOP 02:</span><span>{{ parseFloat(buro.cuentas_mop_02).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP 03:</span><span>{{ parseFloat(buro.cuentas_mop_03).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP 04:</span><span>{{ parseFloat(buro.cuentas_mop_04).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP 05:</span><span>{{ parseFloat(buro.cuentas_mop_05).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP 06:</span><span>{{ parseFloat(buro.cuentas_mop_06).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP 07:</span><span>{{ parseFloat(buro.cuentas_mop_07).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP UR:</span><span>{{ parseFloat(buro.cuentas_mop_ur).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP 96:</span><span>{{ parseFloat(buro.cuentas_mop_96).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP 97:</span><span>{{ parseFloat(buro.cuentas_mop_97).toFixed(0) }}</span></li>
          <li><span>Cuentas MOP 99:</span><span>{{ parseFloat(buro.cuentas_mop_99).toFixed(0) }}</span></li>
          <li><span>Número solicitudes en los últimos 6 meses:</span><span>{{ buro.numero_solicitudes_ultimos_6_meses }}</span></li>
          <li><span>Porcentaje límite de crédito utilizado revolventes:</span><span>{{ buro.pct_limite_credito_utilizado_revolventes }}</span></li>
          <li><span>Número total de cuentas despacho cobranza:</span><span>{{ buro.numero_total_solicitudes_despachos_cobranza }}</span></li>
          <li><span>Total pagos fijos:</span><span>${{ $helper.moneyFormat(buro.total_pagos_pagos_fijos) }}</span></li>
          <li><span>Total límites crédito revolventes:</span><span>${{ $helper.moneyFormat(buro.total_limites_credito_revolventes) }}</span></li>
          <li><span>Total pagos revolventes:</span><span>${{ $helper.moneyFormat(buro.total_pagos_revolventes) }}</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      default() {
        return {
          solicitud: {
            solicitud_id: null,
            solicitud: {

            }
          }
        }
      }
    }
  },
  computed: {
    buro() {
      return this.solicitud.solicitud.buro[this.solicitud.solicitud.buro.length-1];
    }
  }
}
</script>