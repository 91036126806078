import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_ANALYTICPOINT+'/catalogos');

export default {
  obtener_catalogos(activos) {
    return http.get(`/`);
  },

  obtener_catalogo(id, activos) {
    if (typeof activos == 'undefined')
      activos = true
    return activos ? http.get(`/${id}`) : http.get(`/${id}?all=1`);
  },

  crear_catalogo(payload) {
    return http.post(`/`, payload);
  },

  actualizar_catalogo(id, payload) {
    return http.put(`/${id}`, payload);
  },

  buscar_catalogo(codigo) {
    return http.get(`/buscar/${codigo}`);
  }
}
