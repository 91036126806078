import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_COFINANCIAMIENTO+'/otros');

export default {
  obtener_tipos_archivos() {
    return http.get('/valija/documentos_tipos');
  },

  descargar_documento(solicitud, id) {
    return http.get(`/valija/${solicitud}/descargar/${id}`, {responseType: 'blob'})
  },

  obtener_usuarios() {
    return http.get('/alianza/account/usuarios');
  }
}