<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-center">Decisión Modelo</h1>
    </div>
    <div class="row">
      <h3 class="col-sm-12 text-center">Solicitud: {{ solicitud.solicitud_id }}</h3>
    </div>
    <div class="row">
      <h5 class="col-sm-12 text-center">Tipo solicitud: {{ solicitud.data.comite.general.tipo_solicitud }}</h5>
    </div>
    <div class="row">
      <h1 class="col-sm-12 text-center texto_verde">Decisión Final: {{ solicitud.data.comite.general.variablesUnykoo.DesicionFinal }}</h1>
    </div>

    <div class="row my-3">
      <div class="col-sm-12 bloque_titulo">Descripción del negocio</div>
    </div>

    <div class="row item">
      <div class="col-sm-4 titulo">¿Es empleado?</div>
      <div class="col-sm-8 contenido">{{ es_empleado }}</div>
    </div>
    <div class="row item">
      <div class="col-sm-4 titulo">Giro del negocio</div>
      <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.giro_negocio.segundo_nivel }}</div>
    </div>
    <div class="row item">
      <div class="col-sm-4 titulo">Descripción del negocio</div>
      <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.descripcion_giro_negocio }}</div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-6 espacio">
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Score modelo V1</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.PuntajeTotal }}</div>
        </div>
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Decisión modelo V1</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.ScoreV1 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje colateral</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.Colateral }}<small v-if="solicitud.data.comite.general.Scoring.avatar">{{ solicitud.data.comite.general.Scoring.avatar.probabilidad_informacion_cualitativa_false }}</small></div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje carácter</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.Caracter }} <small v-if="riesgo_avatar">{{ riesgo_avatar }}</small></div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje capacidad</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.Capacidad }} <small v-if="avatar('probabilidad_informacion_cuantitativa_falsa')">{{ avatar('probabilidad_informacion_cuantitativa_falsa') }}</small></div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje capital</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.Capital }} <small v-if="avatar('probabilidad_fraude')">{{ avatar('probabilidad_fraude') }}</small></div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje ciclo económico</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.CicloEconomico }} <small v-if="avatar('comportamiento_economico')">{{ avatar('comportamiento_economico') }}</small></div>
        </div>
        <hr>
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Score Modelo V3</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.scoreV3 }}</div>
        </div>
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Decisión Modelo V3</div>
          <div class="col-sm-8 contenido">{{ decision_v3 }}</div>
        </div>
      </div>
      <div class="col-sm-6 espacio">
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Score modelo V2</div>
          <div class="col-sm-8 contenido">{{ parseFloat(variablesUnykoo.SumaScore).toFixed(2) }}</div>
        </div>
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Decisión modelo V2</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.ScoreV2 }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje cliente</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.ConceptoCliente }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje capacidad de pago</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.CapacidadPago }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje colateral</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.ConceptoColateral }} <small v-if="avatar('probabilidad_informacion_cualitativa_falsa')">{{ avatar('probabilidad_informacion_cualitativa_falsa') }}</small></div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje comportamiento pago</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.ComportamientoPago }} <small v-if="avatar('probabilidad_informacion_cualitativa_falsa')">{{ avatar('probabilidad_informacion_cualitativa_falsa') }}</small></div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Puntuaje negocio</div>
          <div class="col-sm-8 contenido">{{ variablesUnykoo.ConceptoNegocio }} <small v-if="avatar('comportamiento_economico')">{{ avatar('comportamiento_economico') }}</small></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gchart from '@/helpers/gcharts'

export default {
  props: {
    solicitud: {
      type: Object,
      required: true,
    }
  },
  methods: {
    avatar(campo) {
      if (!this.solicitud.data.comite.general.Scoring.avatar[campo])
        return '';

      return this.solicitud.data.comite.general.Scoring.avatar[campo]
    }
  },
  computed: {
    es_empleado() {
      if (this.solicitud.data.comite.general.ServiciosOriginacion.es_empleado)
        return this.solicitud.data.comite.general.ServiciosOriginacion.es_empleado == 1 ? 'Si' : 'No';
      else
        return 'Sin información';
    },
    riesgo_avatar() {
      if (this.solicitud.data.comite.general.Scoring.avatar || this.solicitud.data.comite.general.Scoring.avatar.riesgo_persona)
        return '(+.1)';

      let riesgo_persona = this.solicitud.data.comite.general.Scoring.avatar.riesgo_persona;

      if (riesgo_persona >= 1.1 && riesgo_persona < 3.1)
        return '(0)';
      else if (riesgo_persona > 3.1)
        return '(-.1)';
    },
    variablesUnykoo() {
      return this.solicitud.data.comite.general.variablesUnykoo;
    },
    decision_v3() {
      if (this.variablesUnykoo.scoreV3 <= 405)
        return 'Rechazar';
      else if (this.variablesUnykoo.scoreV3 > 405 && this.variablesUnykoo.scoreV3 <= 428)
        return 'Discutir';
      else
        return 'Aceptar';
    }
  }
}
</script>

<style lang="scss" scoped>
.espacio {
  padding: 5px 20px;
}
.texto_verde {
  color: #28a745 !important;
}

.bloque_titulo {
  background-color: #0781cd;
  color: #fefefe;
  font-size: 1.5rem;
  margin-top: 20px;
  padding: 2% 0 2% 6%;
}

.bloque_verde {
  background-color: #24b361 !important;
  color: #fff;
}

.item {
  .titulo, .contenido {
    padding: 10px 15px;
  }

  .contenido {
    text-align: right;
  }
}

.item:nth-child(2n+0) {
  background-color: #e6e6e6;
}
</style>