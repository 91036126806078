<template>
  <div>
    <div class="row">
      <h2 class="col-sm-12">Capacidad de pago</h2>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12 col-lg-5">
        <div class="bloque">
          <div class="title">Resumen de cuentas</div>
          <div class="body">
            <ul>
              <li><span>Monto solicitado:</span><span>${{ $helper.moneyFormat(solicitud.solicitud.importe_solicitado) }}</span></li>
              <li><span>Pago requerido:</span><span>${{ $helper.moneyFormat(solicitud.solicitud.pago_requerido) }}</span></li>
              <li><span>Total monto propuesto:</span><span>${{ $helper.moneyFormat(solicitud.solicitud.monto_propuesto) }}</span></li>
              <li><span>Ingresos totales promedio:</span><span>${{ $helper.moneyFormat(solicitud.solicitud.calculos_ocr.ingresos_totales) }}</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-7">
        <div v-for="(cuenta, index) in cuentas_ocr" class="bloque">
          <div class="title">Cuenta</div>
          <div class="body">
            <div class="row">
              <div class="col-sm-12">
                <ul>
                  <li><span>Ingresos:</span><span>${{ $helper.moneyFormat(cuenta.income) }}</span></li>
                  <li><span>Máximo:</span><span>${{ $helper.moneyFormat(cuenta.maximum_deposit) }}</span></li>
                  <li><span>Mínimo:</span><span>${{ $helper.moneyFormat(cuenta.minimum_deposit) }}</span></li>
                  <!-- <li><span>Desv. Est.:</span><span>{{ cuenta.transaction_size_volatility ? cuenta.transaction_size_volatility : 0 }}%</span></li> -->
                  <li><span>Calificación:</span><span>{{ cuenta.calificacion }}</span></li>
                  <li><span>Pago posible:</span><span>${{ $helper.moneyFormat(cuenta.pago_posible) }}</span></li>
                  <li><span>Monto propuesto:</span><span>${{ $helper.moneyFormat(cuenta.monto_propuesto) }}</span></li>
                  <li><span>PTI:</span><span>{{ (cuenta.pago_ingresos*100) }}%</span></li>
                  <li><span>DTI:</span><span>{{ solicitud.solicitud.dti }}%</span></li>
                </ul>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12">
                <div class="title">Mensaje de advertencias</div>
                <div v-if="cuenta.mensajes.length == 0">No hay mensajes de advertencias.</div>
                <template v-else>
                <ul v-for="mensaje in cuenta.mensajes">
                  <li><span>Fecha:</span><span>{{ mensaje.fecha }}</span></li>
                  <li><span>Balance:</span><span>{{ mensaje.balance }}</span></li>
                  <li><span>Calculado:</span><span>{{ mensaje.calculado }}</span></li>
                  <li><span>Mensaje:</span><span>{{ mensaje.mensaje }}</span></li>
                </ul>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gchart from '@/helpers/gcharts'

export default {
  props: {
    solicitud: {
      type: Object
    }
  },
  async mounted() {
    await gchart();

    if (this.solicitud.solicitud.ocrolus)
      setTimeout(this.chartData, 500);
  }
  ,methods: {
    async chartData() {
      this.$refs.chart_loading.style.display = 'none';
      await gchart();

      google.charts.load('current', {packages: ['corechart']});
      let ocrolus = this.solicitud.solicitud.ocrolus;
      for(let i=0; i<ocrolus.length; i++) {
        ocrolus[i][1] = parseFloat(ocrolus[i][1]);
      }
      google.charts.setOnLoadCallback(() => {
        let chart = new google.visualization.DataTable();
        chart.addColumn('string','Fechas');
        chart.addColumn('number','Depósitos');

        chart.addRows(ocrolus);

        let options = {
          hAxis: {
            title: 'Montos'
          },
          xAxis: {
            title: 'Fecha',
            minValue: 0
          }
        };

        let objChart = new google.visualization.AreaChart(document.getElementById('chart_pagos'));
        objChart.draw(chart, options);
      })
    }
  },
  computed: {
    cuentas_ocr() {
      let cuentas = [];

      this.solicitud.solicitud.ocr.forEach(cuenta => {
        if (cuenta.income > 0)
          cuentas.push(cuenta);
      })

      return cuentas;
    }
  }
}
</script>

<style lang="scss" scoped>
  #char_pagos {
    max-width: 100%;
    height: 300px;
  }

  .bloque {
    border: solid 1px hsl(0, 0%, 80%);
    padding: 20px;

    .title {
      background-color: #0781cd;
      color: #fff;
      font-size: 1.5em;
      padding: 10px;
    }

    .body {
      // padding: 10px;

      ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
          padding: 10px;

          span {
            width: 48%;
            text-align: right;
            display: inline-block;
          }

          span:nth-child(1) {
            text-align: left;
            font-weight: bold;
          }
        }

        li:nth-child(2n+0) {
          background-color: hsl(0,0%,90%);
        }
      }
    }
  }
</style>