<template>
  <Modal :options="{width: '40vw',type: (resolucion == 'aceptado' ? 'success' : 'danger')}" @close="$emit('close')">
    <form @submit.prevent="votar_solicitud">
      <div class="title">Votación</div>
      <div class="body">
        <div class="row mb-3">
          <div class="col-md-5 col-sm-12"><label for="resolucion">Resolución:</label> <select v-model="voto.resolucion" name="resolucion" id="resolucion" class="form-control" disabled>
            <option value="aceptado">Aprobado</option>
            <option value="rechazado">Rechazado</option>
          </select></div>
          <div class="col-md-4 col-sm-12"><label for="garantia">Garantias:</label><input type="text" name="garantia" id="garantia" class="form-control" :value="'$'+($helper.moneyFormat(solicitud.solicitud.op_variables.valor))" disabled></div>
          <div class="col-md-3 col-sm-12"><label for="aforo">Aforo:</label> <input v-model="voto.aforo" name="aforo" id="aforo" type="text" class="form-control" disabled></div>
        </div>
        <div class="row">
          <!-- <div class="col-md-5 col-sm-12"><label for="monto_propuesto">Monto propuesto:</label> <input v-model="voto.monto" name="monto_propuesto" id="monto_propuesto" type="text" class="form-control"></div> -->
          <div class="col-md-5 col-sm-12"><label for="monto_propuesto">Monto propuesto:</label> <InputCompuesto v-model="voto.monto" name="monto_propuesto" id="monto_propuesto" label="$" :formats="['moneyFormat']"/></div>
          <div class="col-md-4 col-sm-12"><label for="tasa">Tasa:</label> <input v-model="voto.tasa_interes" name="tasa" id="tasa" type="number" min="1" max="100" class="form-control"></div>
          <div class="col-md-3 col-sm-12"><label for="plazo">Plazo:</label> 
            <select v-model="voto.plazo" name="plazo" id="plazo" class="form-control">
              <option v-for="plazo in catalogo_plazos.opciones" :value="plazo.valor">{{ plazo.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12"><label for="razon">Razón:</label>
            <select v-model="voto.razon" name="razon" id="razon" class="form-control">
              <option v-for="opcion in catalogo.opciones" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label for="observaciones" class="col-form-label col-sm-12">Observaciones:</label>
            <textarea v-model="voto.observaciones" name="observaciones" id="observaciones" cols="30" rows="10" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2">Votar</button>
            <button class="btn btn-danger" type="button" @click.prevent="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import InputCompuesto from '@/components/Form/InputCompuesto';

import api from '@/apps/analyticpoint/api/comite/solicitudes';
import apiCatalogo from '@/apps/analyticpoint/api/comite/catalogos';

export default {
  components: {
    Modal, InputCompuesto
  },
  props: {
    solicitud: {
      type: Object
    },
    resolucion: {
      type: String
    }
  },
  data() {
    return {
      voto: {
        resolucion: 'aceptado',
        aforo: 0,
        monto: 0,
        tasa_interes: 0,
        plazo: 0,
        razon: null,
        observaciones: null,
        acepta_modelo: 'si'
      },
      catalogo: {
        opciones: []
      },
      catalogo_plazos: {
        opciones: []
      },
      mostrar_catalogo: false
    }
  },
  mounted() {
    this.voto.resolucion = this.resolucion;
    this.voto.aforo = this.solicitud.solicitud.aforo;
    this.voto.monto = parseFloat(this.solicitud.monto_original).toFixed(2);
    this.voto.tasa_interes = this.solicitud.solicitud.tasa_propuesta;

    let plazo = parseInt(this.solicitud.solicitud.plazo);
    this.voto.plazo = plazo < 12 ? (plazo * 12) : plazo;

    this.obtener_catalogo_plazos();

    if (this.resolucion == 'aceptado')
      this.obtener_catalogo_aceptacion();

    if (this.resolucion == 'rechazado')
      this.obtener_catalogo_rechazo();
  },
  methods: {
    async obtener_catalogo_plazos() {
      try {
        this.catalogo_plazos = (await apiCatalogo.buscar_catalogo('plazos')).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e, 'Error');
      }
    },
    async obtener_catalogo_aceptacion() {
      try {
        this.catalogo = (await apiCatalogo.buscar_catalogo('razones_aceptacion')).data;
        this.voto.razon = this.catalogo.opciones[0].valor;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e, 'Error');
      }
    },
    async obtener_catalogo_rechazo() {
      try {
        this.catalogo = (await apiCatalogo.buscar_catalogo('razones_rechazo')).data;
        this.voto.razon = this.catalogo.opciones[0].valor;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e, 'Error');
      }
    },
    async votar_solicitud() {
      try {
        this.voto.acepta_modelos = 'acepta_v1_acepta_bpb';

        let modelo_v1 = this.solicitud.solicitud.modelos.v1.decision;
        let modelo_bpb = this.solicitud.solicitud.modelos.bpb.decision;

        if (this.voto.resolucion == 'aceptado' && modelo_v1 == 'Aceptar' && modelo_bpb == 'Aceptar')
          this.voto.acepta_modelos = 'acepta_v1_acepta_bpb';
        else if (this.voto.resolucion == 'aceptado' && modelo_v1 == 'Aceptar' && modelo_bpb == 'Rechazar')
          this.voto.acepta_modelos = 'acepta_v1_rechaza_bpb'
        else if (this.voto.resolucion == 'aceptado' && modelo_v1 == 'Rechazar' && modelo_bpb == 'Aceptar')
          this.voto.acepta_modelos = 'rechaza_v1_acepta_bpb';
        else if (this.voto.resolucion == 'aceptado' && modelo_v1 == 'Rechazar' && modelo_bpb == 'Rechazar')
          this.voto.acepta_modelos = 'rechaza_v1_rechaza_bpb';
        else if (this.voto.resolucion == 'rechazado' && modelo_v1 == 'Aceptar' && modelo_bpb == 'Rechazar')
          this.voto.acepta_modelos = 'rechaza_v1_acepta_bpb'
        else if (this.voto.resolucion == 'rechazado' && modelo_v1 == 'Rechazar' && modelo_bpb == 'Aceptar')
          this.voto.acepta_modelos = 'acepta_v1_rechaza_bpb';
        else if (this.voto.resolucion == 'rechazado' && modelo_v1 == 'Aceptar' && modelo_bpb == 'Aceptar')
          this.voto.acepta_modelos = 'rechaza_v1_rechaza_bpb';
        

        let res = (await api.enviar_voto(this.solicitud.id, this.voto)).data;
        this.$log.info('res', res);
        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e, 'Error');
      }
    }
  }
  ,watch: {
    voto: {
      handler(nuevo, viejo) {
        this.voto.aforo = (this.solicitud.solicitud.op_variables.valor/nuevo.monto).toFixed(2)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
textarea.form-control {
  height: 150px;
  resize: none;
}
</style>