<template>
  <div>
    <h2>Comportamiento</h2>

    <ul class="nav nav-tabs justify-content-end mb-3">
      <li v-if="solicitud.solicitud.buro && solicitud.solicitud.buro.length > 0" class="nav-item">
        <a :class="'nav-link'+(tipo_buro=='pf'?' active':'')" href="#" @click.prevent="tipo_buro='pf'">Persona física</a>
      </li>
      <li v-if="solicitud.solicitud.buroPm && solicitud.solicitud.buroPm.length > 0" class="nav-item">
        <a :class="'nav-link'+(tipo_buro=='pm'?' active':'')" href="#" @click.prevent="tipo_buro='pm'">Persona moral</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="#" >Representante legal</a>
      </li> -->
    </ul>

    <template v-if="!solicitud.solicitud || (!solicitud.solicitud.buro || !solicitud.solicitud.buroPm)">
      Cargando información...
    </template>
    <template v-else>
      <div class="info_comportamiento">
        <PF v-if="tipo_buro == 'pf'" :solicitud="solicitud" />
        <PM v-if="tipo_buro == 'pm'" :solicitud="solicitud" />
      </div>
    </template>
  </div>
</template>

<script>
import PF from './Comportamiento/PersonaFisica.vue';
import PM from './Comportamiento/PersonaMoral.vue';

export default {
  components: {
    PF,PM
  },
  props: {
    solicitud: {
      type: Object,
      default() {
        return {
          solicitud: {
            solicitud_id: null,
            solicitud: {

            }
          }
        }
      }
    }
  },
  data() {
    return {
      tipo_buro: 'pf'
    }
  },
}
</script>

<style lang="scss">
  .info_comportamiento {
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;

      li {
        padding: 5px 10px;

        span {
          width: 49%;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          display: inline-block;
        }

        span:nth-child(1) {
          font-weight: bold;
        }

        span:nth-child(2) {
          text-align: right;
        }
      }

      li:nth-child(2n+0) {
        background-color: hsl(0, 0%, 90%);
      }
    }
  }
</style>