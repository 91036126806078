<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-center">Comportamiento</h1>
    </div>
    <div class="row">
      <h3 class="col-sm-12 text-center">Solicitud: {{ solicitud.solicitud_id }}</h3>
    </div>
    <div class="row">
      <h5 class="col-sm-12 text-center">Tipo solicitud: {{ solicitud.data.comite.general.tipo_solicitud }}</h5>
    </div>

    <ul class="nav nav-tabs mt-3">
      <li v-for="(buro,index) in buros" class="nav-item"><a href="#" :class="'nav-link'+(buro_index == index ? ' active' : '')" @click.prevent="buro_index=index">{{ tipo_persona(buro) }}</a></li>
    </ul>

    <PF v-if="buro.rfc.length == 13" :solicitud="solicitud" :buro="buro" />
    <PM v-if="buro.rfc.length == 12" :solicitud="solicitud" :buro="buro" />
  </div>
</template>

<script>
import PF from './Comportamiento/PF'
import PM from './Comportamiento/PM'

export default {
  components: {
    PF,PM
  },
  props: {
    solicitud: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      buro_index: 0
    }
  },
  methods: {
    tipo_persona(buro) {
      return buro.rfc.length == 13 ? 'Persona física' : 'Persona Moral';
    }
  },
  computed: {
    buros() {
      let buros = [];

      let buros_pf = this.solicitud.data.comite.general.Scoring.buro;

      if (buros_pf && buros_pf.length > 0) {
        buros.push(buros_pf[buros_pf.length-1]);
      }

      let buros_pm = this.solicitud.data.comite.general.Scoring.buros_pm;
      if (buros_pm && buros_pm.length > 0) {
        buros.push(buros_pm[buros_pm.length-1]);
      }

      return buros;
    },
    buro() {
      return this.buros[this.buro_index];
    }
  }
}
</script>