let initializedGcharts = document.getElementById('googleChartApi');

let resolveInitGChartsPromise, rejectInitGChartsPromise;

const initGChartsPromise = new Promise((resolve, reject) => {
	resolveInitGChartsPromise = resolve;
	rejectInitGChartsPromise = reject;
});

export default function init() {
	if (initializedGcharts)
		return initGChartsPromise;

	initializedGcharts = true;

	// window['googleMapsInit'] = () => resolveInitPromise(window.google);

	let scriptMaps = document.createElement('script');
	scriptMaps.asunc = true;
	scriptMaps.defer = true;
    scriptMaps.src = `https://www.gstatic.com/charts/loader.js`;
    scriptMaps.id = 'googleChartApi'
    scriptMaps.onerror = rejectInitGChartsPromise;
    document.body.appendChild(scriptMaps);

    resolveInitGChartsPromise();
}