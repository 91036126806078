<template>
  <div>
    <div class="info">
      <h2>Resumen de modelo</h2>

      <div class="bloque_info">
        <div class="cabecera">Descripción del negocio</div>
        <div class="linea_info">Nombre: {{ solicitud.solicitud.nombre }}</div>
        <div class="linea_info">¿Es empleado?: {{ solicitud.solicitud.es_empleado == 1 ? 'Sí' : 'No' }}</div>
        <div class="linea_info">Giro del negocio: {{ solicitud.solicitud.giro_negocio ? (solicitud.solicitud.giro_negocio.primer_nivel+' / '+solicitud.solicitud.giro_negocio.segundo_nivel) : '--' }}</div>
        <div class="linea_info">Descripción del negocio: {{ verificar_dato(solicitud.solicitud.descripcion_giro_negocio) }}</div>
        <div class="linea_info">Medio de información: {{ solicitud.solicitud.medio_informacion.canal+(solicitud.solicitud.medio_informacion.sub_sub_canal ? ' ('+solicitud.solicitud.medio_informacion.sub_sub_canal+')' : '') }}</div>
        <div v-if="solicitud.solicitud.promotor" class="linea_info">Promotor: {{ solicitud.solicitud.promotor.nombre }}</div>
      </div>

      <div class="bloque_info">
        <div class="cabecera">Políticas</div>
        <div class="linea_info">¿Cumple políticas? {{ solicitud.solicitud.cumple_politicas == 1 ? 'Sí' : 'No' }}</div>
        <div class="linea_info">Descripción políticas: {{ verificar_dato(solicitud.solicitud.descripcion_politicas) }}</div>
        <div class="linea_info">Observciones Originación: {{ verificar_dato(solicitud.solicitud.observaciones_comercial) }}</div>
        <div class="linea_info">Observaciones Analytic Point: {{ verificar_dato(solicitud.solicitud.observaciones_originacion) }}</div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-lg-4">
          <div class="bloque_info">
            <div class="cabecera">Colateral</div>
            <div class="linea_info">Entidad: {{ ult_garantia.entidad ? ult_garantia.entidad.nombre : ult_garantia.estado }}</div>
            <div class="linea_info">Valor propiedad: ${{ $helper.moneyFormat(verificar_dato(solicitud.solicitud.op_variables.valor,0)) }}</div>
            <div class="linea_info">Comercialización: {{ verificar_dato(solicitud.solicitud.op_variables.tiempo_comercializacion) }}</div>
            <div class="linea_info">Evaluación sobre 5: {{ verificar_dato(parseFloat(solicitud.solicitud.op_variables.score).toFixed(2)) }}</div>
            <div class="linea_info">Aforo: {{ verificar_dato(solicitud.solicitud.aforo) }}</div>
            <div class="linea_info">Tasa propuesta: {{ verificar_dato(solicitud.solicitud.tasa_propuesta) }}%</div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4">
          <div class="bloque_info">
            <div class="cabecera">Capacidad</div>
            <div class="linea_info">Monto solicitado: ${{ $helper.moneyFormat(solicitud.solicitud.importe_solicitado) }}</div>
            <div class="linea_info">Pago requerido: ${{ $helper.moneyFormat(solicitud.solicitud.pago_requerido) }}</div>
            <div class="linea_info">Ingresos: ${{ $helper.moneyFormat(solicitud.solicitud.calculos_ocr.ingresos_totales) }}</div>
            <div class="linea_info">Monto propuesto: ${{ $helper.moneyFormat(solicitud.solicitud.calculos_ocr.monto_propuesto_total) }}</div>
            <div class="linea_info">DTI: {{ (solicitud.solicitud.dti) }}%</div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4">
          <div class="bloque_info">
            <div class="cabecera">Comportamiento</div>
            <div class="linea_info">Cuentas abiertas: {{ ult_buro.total_cuentas_abiertas }}</div>
            <div class="linea_info">Cuentas MOP 01: {{ ult_buro.total_cuentas_mop_01 }}</div>
            <div class="linea_info">Cuentas atrasos menores: {{ ult_buro.total_cuentas_atrasos_menores }}</div>
            <div class="linea_info">Cuentas MOP 96: {{ ult_buro.cuentas_mop_96 }}</div>
            <div class="linea_info">Cuentas MOP 97: {{ ult_buro.cuentas_mop_97 }}</div>
            <div class="linea_info">Total créditos máximos pagos fijos: ${{ $helper.moneyFormat(ult_buro.total_creditos_maximos_pagos_fijos) }}</div>
            <div class="linea_info">Total saldos actuales pagos fijos: ${{ $helper.moneyFormat(ult_buro.total_saldos_actuales_pagos_fijos) }}</div>
            <div class="linea_info">Score buro: {{ ult_buro.score }}</div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
  

  export default {
    props: {
      solicitud: {
        type: Object,
        default() {
          return {
            solicitud_id: null,
            solicitud: {
              es_empleado: 0,
              giro_negocio: {
                primer_nivel: '',
                segundo_nivel: ''
              },
              op_variables: {
                valor: 0,
                score: 0,
                tiempo_comercializacion: ''
              },
              garantias_originacion: [
                {
                  estado: ''
                }
              ],
            },
          }
        }
      }
    },
    data() {
      return {
        
      }
    },
    methods: {
      verificar_dato(dato, default_val) {
        return dato ? dato : (default_val ? default_val : '--');
      }
    },
    computed:{
      ult_garantia() {
        return this.solicitud.solicitud.garantias_originacion[this.solicitud.solicitud.garantias_originacion.length-1];
      },
      ult_buro() {
        return this.solicitud.solicitud.buro[this.solicitud.solicitud.buro.length-1];
      }
    }
  }
</script>

<style lang="scss" scope>
  .bloque_info {
    border: solid 1px #e9e9e9;
    padding: 20px 15px;
    margin-bottom: 10px;

    .cabecera {
      background-color: #0781cd;
      color: #fff;
      font-size: 1.5em;
      padding: 10px 15px;
    }

    .linea_info {
      padding: 10px 15px;
    }

    .linea_info:nth-child(2n+1) {
      background-color: #ebebeb;
    }
  }
</style>