<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-12 bloque_titulo">Persona moral</div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="row item">
          <div class="col-sm-4 titulo">Referencia</div>
          <div class="col-sm-8 contenido">{{ buro.nombre_cliente }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Tipo de cartera</div>
          <div class="col-sm-8 contenido">{{ buro.calificacion_cartera ?buro.calificacion_cartera : 'Sin información' }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Créditos cerrados</div>
          <div class="col-sm-8 contenido">{{ buro.calculosPM.creditosCerrados }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Número de cuentas abiertas</div>
          <div class="col-sm-8 contenido">{{ buro.calculosPM.creditosAbiertos }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Otras financieras / simples</div>
          <div class="col-sm-8 contenido">{{ buro.calculosPM.otrasFinancieraSimples }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Otras financieras / Arrend. Puro</div>
          <div class="col-sm-8 contenido">{{ buro.calculosPM.otrasFinancieraArrendadora }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Banco / simple</div>
          <div class="col-sm-8 contenido">{{ buro.calculosPM.bancoSimple }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Banco / línea de crédito</div>
          <div class="col-sm-8 contenido">{{ buro.calculosPM.bancoLineaCredito }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Banco / tarjeta empresarial</div>
          <div class="col-sm-8 contenido">{{ buro.calculosPM.bancoTarjetaEmpresarial }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Saldo vigente abiertas</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.calculosPM.saldoVigenteAbiertas) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Saldo Mora 1</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.calculosPM.saldoMoraUno) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Saldo Mora 2</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.calculosPM.saldoMoraDos) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Saldo total</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(buro.calculosPM.saldoTotal) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Salso vencido</div>
          <div class="col-sm-8 contenido">%{{ $helper.moneyFormat(buro.calculosPM.saldoVencido) }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12 bloque_titulo text-center">Datos Generales</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">RCF</div>
          <div class="col-sm-8 contenido">{{ buro.rfc }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Nombre del cliente</div>
          <div class="col-sm-8 contenido">{{ buro.nombre_cliente }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Dirección 1</div>
          <div class="col-sm-8 contenido">{{ buro.direccion }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Código postal</div>
          <div class="col-sm-8 contenido">{{ buro.codigo_postal }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Ciudad</div>
          <div class="col-sm-8 contenido">{{ buro.ciudad }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Estado</div>
          <div class="col-sm-8 contenido">{{ buro.estado }}</div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12 bloque_titulo">Resumen créditos activos</div>
    </div>

    <div class="row my-3">
      <div class="col-sm-12">
        <table class="resumen_creditos_activos">
          <thead>
            <tr>
              <th>Tipo otorgante</th>
              <th>Cuentas abiertas</th>
              <th>Cuentas en MXP</th>
              <th>Original</th>
              <th>Saldo actual</th>
              <th>Vigente</th>
              <th>1-29 días</th>
              <th>30-59 días</th>
              <th>60-89 días</th>
              <th>90-119 días</th>
              <th>12-179 días</th>
              <th>Más 180 días</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="credito in buro.creditosActivos">
              <td>{{ credito.tipoUsuario }}</td>
              <td>{{ credito.total }}</td>
              <td>{{ credito.cuentasMxp }}</td>
              <td>${{ $helper.moneyFormat(credito.original) }}</td>
              <td>${{ $helper.moneyFormat(credito.saldoActual) }}</td>
              <td>${{ $helper.moneyFormat(credito.saldovigente) }}</td>
              <td>${{ $helper.moneyFormat(credito.saldo29) }}</td>
              <td>${{ $helper.moneyFormat(credito.saldo59) }}</td>
              <td>${{ $helper.moneyFormat(credito.saldo89) }}</td>
              <td>${{ $helper.moneyFormat(credito.saldo119) }}</td>
              <td>${{ $helper.moneyFormat(credito.saldo179) }}</td>
              <td>${{ $helper.moneyFormat(credito.saldoMas180) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12 bloque_titulo">Resumen créditos liquidados</div>
    </div>

    <div class="row my-3">
      <div class="col-sm-12">
        <table class="resumen_creditos_activos">
          <thead>
            <tr>
              <th>#</th>
              <th>Tipo otorgante</th>
              <th>Cuentas cerradas</th>
              <th>Cuentas en MXP</th>
              <th>Original</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(credito,index) in buro.creditosLiquidados">
              <td>{{ index+1 }}</td>
              <td>{{ credito.tipoUsuario }}</td>
              <td>{{ credito.total }}</td>
              <td>{{ credito.cuentasMxp }}</td>
              <td>${{ $helper.moneyFormat(credito.original) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      required: true
    },
    buro: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.bloque_titulo {
  background-color: #0781cd;
  color: #fefefe;
  font-size: 1.5rem;
  margin-top: 20px;
  padding: 2% 0 2% 6%;
}
.item {
  .titulo, .contenido {
    padding: 10px 15px;
  }

  .contenido {
    text-align: right;
  }
}

.item:nth-child(2n+1) {
  background-color: #e6e6e6;
}

.resumen_creditos_activos {
  width: 100%;

  thead {
    tr {
      th {
        background-color: #24B361;
        font-size: 1.3em;
        text-align: center;
        padding: 8px 13px;
      }
    }
  }

  tbody {
    tr {
      td {
        text-align: center;
        padding: 8px 13px;
      }
    }

    tr:nth-child(2n+0) {
      background-color: #e6e6e6;
    }
  }
}
</style>