<template>
  <div>
    <Modal :options="{close:true}" @close="$emit('close')">
      <div class="title">Preview {{ documento.nombre_original }}</div>
      <div class="body">
        <div v-if="!data">Cargando documento...</div>
        <div v-else>
          <iframe style="width: 100%; height: 600px" :src="data" frameborder="0"></iframe>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/fyc/api/otros'

export default {
  components: {
    Modal
  },
  props: {
    documento: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
      tipo: null
    }
  },
  async mounted() {
    try {
      let data = (await api.descargar_documento(this.documento.solicitud_id,this.documento.id)).data;
      this.data = URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
    }catch(e) {
      this.$log.info('error',e);
      this.$helper.showAxiosError(e,'Error');
    }
  }
}
</script>