<template>
  <div>
    <div class="row my-3">
      <div class="col-sm-6">
        
      </div>
      <h1 class="col-sm-6 text-right">
        Solicitud {{ !solicitud ? '...' : solicitud.solicitud_id }}
      </h1>
    </div>

    <div v-if="!solicitud" class="row">
      <div class="col-sm-12 text-center">Cargando la solicitud...</div>
    </div>
    <template v-else>
    <div class="row">
      <div class="col-sm-12">
        <ul class="nav nav-tabs justify-content-center">
          <li v-if="solicitud.data.creditos" class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'creditos' ? ' active' : '')" @click.prevent="tab_active='creditos'">Créditos</a></li>
          <li v-if="solicitud.data.analyticpoint" class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'analyticpoint' ? ' active' : '')" @click.prevent="tab_active='analyticpoint'">Analytic Point</a></li>
          <li v-if="solicitud.data.comite" class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'comite' ? ' active' : '')" @click.prevent="tab_active='comite'">Comité</a></li>
          <li v-if="solicitud.data.formalizacion" class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'formalizacion' ? ' active' : '')" @click.prevent="tab_active='formalizacion'">Formalización</a></li>
          <li v-if="solicitud.data.valija" class="nav-item"><a href="#" :class="'nav-link'+(tab_active == 'documentos' ? ' active' : '')" @click.prevent="tab_active='documentos'">Documentos</a></li>
        </ul>
      </div>
    </div>

    <Creditos v-if="tab_active == 'creditos'" :solicitud="solicitud" />
    <AnalyticPoint v-if="tab_active == 'analyticpoint'" :solicitud_raw="solicitud.data.analyticpoint" />
    <Comite v-if="tab_active == 'comite'" :solicitud="solicitud" />
    <Formalizacion v-if="tab_active == 'formalizacion'" :solicitud="solicitud" />
    <Valija v-if="tab_active == 'documentos'" :solicitud="solicitud" />
    </template>
  </div>
</template>

<script>
import api from '@/apps/fyc/api/solicitudes'

import Creditos from './Creditos/Info';
import Comite from './Comite/Visualizador';
import Formalizacion from './Formalizacion/Visualizador';
import Valija from './Valija/Documento';
import AnalyticPoint from './AnalyticPoint/Visualizador';

export default {
  components: {
    Creditos, Comite, Formalizacion, Valija, AnalyticPoint
  },
  data() {
    return {
      solicitud: null,
      tab_active: 'creditos'
    }
  },
  mounted() {
    this.obtener_solicitud();
  },
  methods: {
    async obtener_solicitud() {
      try {
        this.solicitud = (await api.obtener_solicitud(this.$route.params.id)).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>