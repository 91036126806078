<template>
  <div>
    <div class="row mb-3">
      <h2 class="col-sm-12 text-center">Persona moral</h2>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <ul>
          <li><span>Tipo de cartera:</span><span>{{ buro.calificacion_cartera ? buro.calificacion_cartera : 'Sin información' }}</span></li>
          <li><span>Créditos cerrados:</span><span>{{ buro.calculos_pm.cuentas_cerradas }}</span></li>
          <li><span>Créditos abiertos:</span><span>{{ buro.calculos_pm.cuentas_activas }}</span></li>
          <li><span>Otras financieras/simples:</span><span>{{ buro.calculos_pm.otras_financieras_simples }}</span></li>
          <li><span>Otras financieras/arrend. puro:</span><span>{{ buro.calculos_pm.financiera_arrendadora }}</span></li>
          <li><span>Banco/simple:</span><span>{{ buro.calculos_pm.banco_simple }}</span></li>
          <li><span>Banco/línea de crédito:</span><span>{{ buro.calculos_pm.banco_linea_credito }}</span></li>
          <li><span>Banco/tarejta empresarial:</span><span>{{ buro.calculos_pm.banco_tarjeta_empresarial }}</span></li>
          <li><span>Saldo vigente abiertas:</span><span>{{ buro.calculos_pm.saldo_vigente_abiertas }}</span></li>
          <li><span>Saldo mora 1:</span><span>{{ buro.calculos_pm.saldo_mora_uno }}</span></li>
          <li><span>Saldo mora 2:</span><span>{{ buro.calculos_pm.saldo_mora_dos }}</span></li>
          <li><span>Saldo total:</span><span>{{ buro.calculos_pm.saldo_total }}</span></li>
          <li><span>Saldo vencido:</span><span>{{ buro.calculos_pm.saldo_vencido }}</span></li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-6">
        <ul>
          <li><span>RFC:</span><span>{{ buro.rfc }}</span></li>
          <li><span>Nombre del cliente:</span><span>{{ buro.nombre_cliente }}</span></li>
          <li><span>Dirección:</span><span>{{ buro.direccion }}</span></li>
          <li><span>Código postal:</span><span>{{ buro.codigo_postal }}</span></li>
          <li><span>Ciudad:</span><span>{{ buro.ciudad }}</span></li>
          <li><span>Estado:</span><span>{{ buro.estado }}</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      default() {
        return {
          solicitud: {
            solicitud_id: null,
            solicitud: {

            }
          }
        }
      }
    }
  },
  computed: {
    buro() {
      return this.solicitud.solicitud.buroPm[this.solicitud.solicitud.buroPm.length-1];
    }
  }
}
</script>