<template>
  <div>
    <div class="row">
      <h1 class="col-sm-12">Decisión modelo</h1>
    </div>

    <div class="bloque_info">
      <div class="cabecera">Descripción del negocio</div>
      <div class="linea_info">¿Es empleado? {{ solicitud.solicitud.es_empleado == 1 ? 'Sí' : 'No' }}</div>
      <div class="linea_info">Giro del negocio: {{ solicitud.solicitud.giro_negocio ? (solicitud.solicitud.giro_negocio.primer_nivel+' / '+solicitud.solicitud.giro_negocio.segundo_nivel) : '--' }}</div>
      <div class="linea_info">Descripción del negocio: {{ solicitud.solicitud.descripcion_giro_negocio }}</div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="bloque_info">
          <div class="cabecera"><span>Score Modelo V1</span><span>{{ parseFloat(solicitud.solicitud.modelos.v1.score).toFixed(2) }}</span></div>
          <div class="linea_info"><span>Decisión:</span><span>{{ solicitud.solicitud.modelos.v1.decision }}</span></div>
          <div class="linea_info"><span>Colateral:</span><span>{{ parseFloat(solicitud.solicitud.modelos.v1.score_garantias).toFixed(2) }}</span></div>
          <div class="linea_info"><span>Capacidad:</span><span>{{ parseFloat(solicitud.solicitud.modelos.v1.score_capacidad).toFixed(2) }}</span></div>
          <div class="linea_info"><span>Carácter:</span><span>{{ parseFloat(solicitud.solicitud.modelos.v1.score_caracter).toFixed(2) }}</span></div>
          <div class="linea_info"><span>Variables internas:</span><span>{{ parseFloat(solicitud.solicitud.modelos.v1.score_variables_internas).toFixed(2) }}</span></div>
          <div class="linea_info"><span>Variables externas:</span><span>{{ parseFloat(solicitud.solicitud.modelos.v1.score_variables_externas).toFixed(2) }}</span></div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="bloque_info">
          <div class="cabecera"><span>Score Modelo BpB</span><span>{{ parseFloat(solicitud.solicitud.modelos.bpb.score).toFixed(2) }}</span></div>
          <div class="linea_info"><span>Decisión:</span><span>{{ solicitud.solicitud.modelos.bpb.decision }}</span></div>
        </div>
        <div v-if="solicitud.solicitud && solicitud.solicitud.ekatena" class="bloque_info">
          <div class="cabecera"><span>Score Ekatena</span><span>{{ solicitud.solicitud.ekatena.score }}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      default() {
        return {
          solicitud: {
            es_empleado: 1,
            giro_negocio: {
              primer_nivel: null,
              segundo_nivel: null
            },
            descripcion_giro_negocio: null
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scope>
.bloque_info {
    border: solid 1px #e9e9e9;
    padding: 20px 15px;
    margin-bottom: 10px;

    span {
      width: 49%;
      display: inline-block;
    }
    
    span:nth-child(2) {
      text-align: right;
    }

    .cabecera {
      background-color: #0781cd;
      color: #fff;
      font-size: 1.5em;
      padding: 10px 15px;

      span {
        color: #fff;
      }
    }

    .linea_info {
      padding: 10px 15px;
    }

    .linea_info:nth-child(2n+1) {
      background-color: #ebebeb;
    }
  }
</style>