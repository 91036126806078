<template>
  <div>
    <div class="row">
      <h3 class="col-sm-12">Áreas</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      required: true
    }
  }
}
</script>