<template>
	<div>
		<div class="input-group">
			<div v-if="label != '' && label != null" class="input-group-prepend">
				<span class="input-group-text">{{ label }}</span>
			</div>
			<input :type="type" :name="name" :id="id" class="form-control" :placeholder="placeholder" @blur="actualizar" @focus="limpiar" :disabled="disabled" :ref="id" :readonly="readonly" >
			<div v-if="label_right != '' && label_right != null" class="input-group-append">
				<span class="input-group-text">{{ label_right }}</span>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			label: String,
			label_right: String,
			type: {
				type: String
				,default: 'text'
			}
			,value: {
				type: [String,Number]
				,default() {
					return '0.00'
				}
			}
			,readonly: {
				type: Boolean
				,default: false
			}
			,placeholder: String
			,name: {
				type: String,
				required: true
			}
			,id: {
				type: String,
				required: true
			}
			,formats: {
				type: Array
				,default: function() {
					return [];
				}
			}
			,disabled: {
				type: Boolean
				,default: false
			}
			,custome: {
				type: Function
			}
		}
		,model: {
			prop: 'value'
			,event: 'update'
		}
		,mounted() {
			if (this.$refs[this.id])
				this.$refs[this.id].value = this.format_value(this.value)
		}
		,methods: {
			isMoneyFormat: function() {
				if (this.formats.length == 0)
					return false;

				let isMoneyFormat = false;

				this.formats.forEach(helper => {
					if (helper == 'moneyFormat')
						isMoneyFormat = true;
				})

				return isMoneyFormat;
			}
			,actualizar: function(e) {
				let valor = e.target.value;

				let limpio = valor;

				if (this.isMoneyFormat())
					limpio = valor ? Number(String(valor).replace(/[^0-9.]+/g, '')) : '';

				this.$emit('update', limpio);

				e.target.value = this.format_value(limpio);
			}
			,limpiar: function(e) {
				let valor = e.target.value;
				
				if (this.isMoneyFormat())
					valor = Number(String(valor).replace(/[^0-9.]+/g, ''));

				e.target.value = valor != 0 ? valor : this.value;
			}
			,format_value: function(val) {
				this.$log.info('formats', this.formats.length);
				if (this.formats.length == 0) {
					return val;
				}else {
					let that = this;
					let value = null;
					this.formats.forEach(function(helper) {
						// that.$log.info('helper', helper);
						switch(helper) {
							case 'moneyFormat':
								value = that.$helper.moneyFormat(val);
								break;
							case 'custome':
								if (!that.custome || typeof that.custome != 'function')
									throw new Error('Custome debe ser una función que retorne un valor');

								value = that.custome(val);

								if (!value)
									throw new Error('Custome debe ser una función que retorne un valor');
								break;
							default :
								value = val;
								break;
						}
					});

					return value;
				}
			}
		}
		,watch: {
			value: {
				handler: function(val) {
					this.$log.info('value change!', val);
          let limpio = val;
					
					if (this.isMoneyFormat())
						limpio = Number(String(val).replace(/[^0-9.]+/g, ''));

					this.$log.info('id: ', this.id);
					this.$refs[this.id].value = this.format_value(limpio);
				}
			}
		}
	}
</script>