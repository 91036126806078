<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-center">Garantía</h1>
    </div>
    <div class="row">
      <h3 class="col-sm-12 text-center">Solicitud: {{ solicitud.solicitud_id }}</h3>
    </div>
    <div class="row">
      <h5 class="col-sm-12 text-center">Tipo solicitud: {{ solicitud.data.comite.general.tipo_solicitud }}</h5>
    </div>

    <div class="content">
      <div class="row mb-3">
        <div class="col-sm-12">
          <ul class="nav nav-tabs">
            <li v-for="(nav_garantia, index) in solicitud.data.comite.general.garantias" class="nav-item"><a href="#" :class="'nav-link'+(garantia_index == index ? ' active' : '')" @click.prevent="garantia_index=index">{{ nav_garantia.id }}</a></li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <img
            :src="'https://maps.googleapis.com/maps/api/streetview?size=500x400&location='+garantia.latitud+','+garantia.longitud+'&heading='+garantia.heading+'&pitch='+garantia.pitch+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4'" 
            alt="" 
            style="width:100%"
            />
        </div>
        <div class="col-sm-6">
          <img
            :src="'https://maps.googleapis.com/maps/api/staticmap?size=500x400&markers='+garantia.latitud+','+garantia.longitud+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4&scale=1'" 
            alt="" 
            style="width:100%"
            />
        </div>
      </div>

      <div class="row my-3">
        <div class="col-sm-12"><button class="btn btn-primary" @click="ver_fachada=true">Ver Fachada</button></div>
      </div>

      <hr class="mb-3">

      <div class="row">
        <div class="col-sm-6">
          <div class="row item">
            <div class="col-sm-4 titulo">Colateral</div>
            <div class="col-sm-8 contenido">{{ garantia.colateral }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Antiguedad en años</div>
            <div class="col-sm-8 contenido">{{ garantia.edad }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Calle</div>
            <div class="col-sm-8 contenido">{{ garantia.calle }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Número exterior</div>
            <div class="col-sm-8 contenido">{{ garantia.numero_ext }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Colonia</div>
            <div class="col-sm-8 contenido">{{ garantia.colonia }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Municipio</div>
            <div class="col-sm-8 contenido">{{ garantia.municipio }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Ciudad</div>
            <div class="col-sm-8 contenido">{{ garantia.ciudad }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Estado</div>
            <div class="col-sm-8 contenido">{{ garantia.estado }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Código postal</div>
            <div class="col-sm-8 contenido">{{ garantia.cp }}</div>
          </div>
          <div v-if="solicitud.data.comite.general.Scoring.avatar && solicitud.data.comite.general.Scoring.avatar.existencia_gravamen" class="row item">
            <div class="col-sm-4 titulo">Situación</div>
            <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.Scoring.avatar.existencia_gravamen }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Tiene Gravamen</div>
            <div class="col-sm-8 contenido">{{ gravamen }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Tipo propiedad</div>
            <div class="col-sm-8 contenido">{{ garantia.tipo_garantia.nombre }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Propietario</div>
            <div class="col-sm-8 contenido">{{ propiedad }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Tipo propiedad</div>
            <div class="col-sm-8 contenido">{{ garantia.tipo_garantia.nombre }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Valor propiedad</div>
            <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ov.valor) }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Comercializacion</div>
            <div class="col-sm-8 contenido">{{ ov.tiempo_comercialiacion }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Estado de conservación</div>
            <div class="col-sm-8 contenido">{{ ov.estado_conservacion }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Evaluación (sobre 5)</div>
            <div class="col-sm-8 contenido">{{ ov.score }}</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div :id="'map'+garantia.id" style="height: 600px"></div>
        </div>
      </div>
    </div>

    <VisorImagen v-if="ver_fachada" :imagen="garantia.fachada.id" @close="ver_fachada=false" />
  </div>
</template>

<script>
import VisorImagen from './Garantia/Visor';
  import gmaps from '@/helpers/gmaps'

export default {
  components: {
    VisorImagen
  },
  props: {
    solicitud: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      garantia_index: 0,
      ver_fachada: false,
      map: null
    }
  },
  async mounted() {
    const google = await gmaps();
    setTimeout(() => {
      this.cargar_mapa();
    }, 1000);

  },
  methods: {
    cargar_mapa() {
      const lat = parseFloat(this.garantia.latitud);
      const long = parseFloat(this.garantia.longitud);

      this.map = new google.maps.Map(document.getElementById('map' + this.garantia.id), {
        center: { lat: lat, lng: long },
        zoom: 17
      })

      new google.maps.Marker({
        position: new google.maps.LatLng(lat, long),
        map: this.map,
        title: 'Garantía'
      })
    }
  },
  computed: {
    garantia() {
      return this.solicitud.data.comite.general.garantias[this.garantia_index];
    },
    gravamen() {
      if (this.solicitud.data.comite.general.Scoring.avatar && this.solicitud.data.comite.general.Scoring.avatar.existencia_gravamen) 
        return solicitud.data.comite.general.Scoring.avatar.existencia_gravamen;
      else if (this.garantia.existencia_gravamen)
        return this.garantia.existencia_gravamen == 1 ? 'Si' : 'No';
      else
        return 'Sin información'
    },
    propiedad() {
      if (this.solicitud.data.comite.general.Scoring.avatar && this.solicitud.data.comite.general.Scoring.avatar.dueno_colateral)
        return this.solicitud.data.comite.general.Scoring.avatar.dueno_colateral;
      else
        return this.garantia.propia ? 'Es propia' : 'No es propia';
    },
    ov() {
      if (this.garantia.opinion_i360.length == 0 && this.garantia.opinion_rasa.length == 0)
        return {valor: 0}

      if (this.garantia.opinion_i360.length > 0)
        return this.garantia.opinion_i360[0];

      return this.garantia.opinion_rasa[0];
    }
  },
  watch: {
    garantia_index() {
      setTimeout(() => {
        this.cargar_mapa();
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  .titulo, .contenido {
    padding: 10px 15px;
  }

  .contenido {
    text-align: right;
  }
}

.item:nth-child(2n+0) {
  background-color: #e6e6e6;
}
</style>