<template>
  <div>
    <div class="row my-3">
      <h3 class="col-sm-12">Garantia: {{ garantia.calle }}</h3>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Calle:</div>
          <div class="content">{{ garantia.calle }}</div>
        </div>
        <div class="item">
          <div class="title">Colonia:</div>
          <div class="content">{{ garantia.colonia }}</div>
        </div>
        <div class="item">
          <div class="title">Alcaldía / Municipio:</div>
          <div class="content">{{ garantia.municipio }}</div>
        </div>
        <div class="item">
          <div class="title">CP:</div>
          <div class="content">{{ garantia.cp }}</div>
        </div>
        <div class="item">
          <div class="title">Estado:</div>
          <div class="content">{{ garantia.entidad ? garantia.entidad.nombre : (garantia.estado ? garantia.estado : 'No definido') }}</div>
        </div>
        <div class="item">
          <div class="title">Ciudad:</div>
          <div class="content">{{ garantia.ciudad }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Tipo:</div>
          <div class="content">{{ garantia.tipo_garantia.nombre }}</div>
        </div>
        <div class="item">
          <div class="title">Propia:</div>
          <div class="content"> {{ garantia.propia == 1 ? 'Sí' : 'No' }}</div>
        </div>
        <div class="item">
          <div class="title">¿Libre de gravamen?</div>
          <div class="content">{{ garantia.existencia_gravamen == 1 ? 'Sí' : 'No' }}</div>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Recámaras:</div>
          <div class="content">{{ garantia.recamaras }}</div>
        </div>
        <div class="item">
          <div class="title">Baños:</div>
          <div class="content">{{ garantia.banos }}</div>
        </div>
        <div class="item">
          <div class="title">Medios baños:</div>
          <div class="content">{{ garantia.medios_banos }}</div>
        </div>
        <div class="item">
          <div class="title">Estacionamientos:</div>
          <div class="content">{{ garantia.estacionamientos }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Superficie terreno:</div>
          <div class="content">{{ garantia.superficie_terreno }}m</div>
        </div>
        <div class="item">
          <div class="title">Área construída:</div>
          <div class="content">{{ garantia.area_construida }}m</div>
        </div>
        <div class="item">
          <div class="title">Edad propiedad:</div>
          <div class="content">{{ garantia.edad }} años</div>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Valor estimado:</div>
          <div class="content">{{ garantia.valuacion ? '$'+parseFloat(garantia.valuacion).toFixed(2).toLocaleString('es-MX') : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">Valor por m<sup>2</sup>:</div>
          <div class="content">{{ garantia.valuacion_m2 ? '$'+parseFloat(garantia.valuacion_m2).toFixed(2).toLocaleString('es-MX') : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">Intervalo bajo m<sup>2</sup>:</div>
          <div class="content">{{ garantia.intervalo_bajo_m2 ? '$'+parseFloat(garantia.intervalo_bajo_m2).toFixed(2).toLocaleString('es-MX') : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">Intervalo bajo:</div>
          <div class="content">{{ garantia.intervalo_bajo ? '$'+parseFloat(garantia.intervalo_bajo).toFixed(2).toLocaleString('es-MX') : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">Intervalo alto m<sup>2</sup>:</div>
          <div class="content">{{ garantia.intervalo_alto_m2 ? '$'+parseFloat(garantia.intervalo_alto_m2).toFixed(2).toLocaleString('es-MX') : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">Intervalo alto:</div>
          <div class="content">{{ garantia.intevalo_alto ? '$'+parseFloat(garantia.intevalo_alto).toFixed(2).toLocaleString('es-MX') : '--' }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Vista exterior:</div>
          <div class="content">{{ garantia.vista_exterior ? 'Sí' : 'No' }}</div>
        </div>
        <div class="item">
          <div class="title">Roof garden:</div>
          <div class="content">{{ garantia.roof_garden ? 'Sí' : 'No' }}</div>
        </div>
        <div class="item">
          <div class="title">Número nivel:</div>
          <div class="content">{{ garantia.numero_nivel ? garantia.numero_nivel : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">Número pisos:</div>
          <div class="content">{{ garantia.numero_pisos ? garantia.numero_pisos : '--' }}</div>
        </div>
        <div class="item">
          <div class="title">Balcón:</div>
          <div class="content">{{ garantia.balcon ? 'Sí' : 'No' }}</div>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Latitud, Longitud:</div>
          <div class="content">{{ (garantia.latitud ? garantia.latitud : '--') + ', ' + (garantia.longitud ? garantia.longitud : '--') }}</div>
        </div>
        <div class="item">
          <div class="title">Tiempo comercialización:</div>
          <div class="content">{{ tiempo_comercializacion }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Puntuación total:</div>
          <div class="content">{{ puntuacion_total }}</div>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <div v-if="validar_imagenes_google" class="row">
      <div class="col-sm-6 text-center">
        <img :src="'https://maps.googleapis.com/maps/api/streetview?size=500x400&location='+garantia.latitud+','+garantia.longitud+'&heading='+garantia.heading+'&pitch='+garantia.pitch+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4'">
      </div>
      <div class="col-sm-6 text-center">
        <img :src="'https://maps.googleapis.com/maps/api/staticmap?size=500x400&markers=|'+garantia.latitud+','+garantia.longitud+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4&scale=1'">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    garantia: {
      type: Object,
      required: true
    },
    solicitud: {
      type: Object,
      required: true
    }
  },
  computed: {
    tiempo_comercializacion() {
      if (!this.solicitud.data.scoring)
        return '--';

      let inmuebles = this.solicitud.data.scoring.all.inmueble;

      if (!inmuebles)
        return '--';

      let garantia = null;

      for(let i=0; i<inmuebles.length; i++) {
        if (this.garantia.id == inmuebles[i].garantia_id)
          garantia = inmuebles[i];
      }

      return garantia ? garantia.tiempo_estimado_venta : '--'
    },
    puntuacion_total() {
      if (!this.solicitud.data.scoring)
        return '--1';

      let inmuebles = this.solicitud.data.scoring.all.inmueble;

      if (!inmuebles)
        return '--2';

      let garantia = null;

      for(let i=0; i<inmuebles.length; i++) {
        if (this.garantia.id == inmuebles[i].garantia_id)
          garantia = inmuebles[i];
      }

      return garantia ? garantia.puntuacion_total : '--'
    },
    validar_imagenes_google() {
      return this.garantia.latitud && this.garantia.longitud && this.garantia.heading && this.garantia.pitch;
    }
  }
}
</script>

<style lang="scss" scoped>
  .item {
    margin-bottom: 2px;
    border: solid 1px hsl(0,0%, 90%);

    .title, .content {
      padding: 7px 10px;
      display: inline-block;
    }
    .title {
      width: 170px;
      background-color: hsl(0, 0%, 90%);
    }
  }
</style>