import { render, staticRenderFns } from "./PM.vue?vue&type=template&id=f261c162&scoped=true"
import script from "./PM.vue?vue&type=script&lang=js"
export * from "./PM.vue?vue&type=script&lang=js"
import style0 from "./PM.vue?vue&type=style&index=0&id=f261c162&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f261c162",
  null
  
)

export default component.exports