<template>
  <div>
    <div class="row my-3">
      <h3 class="col-sm-12">Observaciones</h3>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 text-right">
          Total items: {{ observaciones.length }} | Total páginas: {{ total_paginas }}
          <div style="display: inline-block;">
            <ul class="pagination justify-content-end">
              <li v-if="pagina_actual > visualizar_paginas" class="page-item"><a href="#" class="page-link" @click.prevent="pagina_actual=1">Inicio</a></li>
              <li :class="'page-item'+(pagina_actual <= 1 ? ' disabled' : '')"><a href="#" class="page-link" @click.prevent="pagina_actual--" :disabled="pagina_actual <= 1">Anterior</a></li>
              <li v-for="pagina in paginas" :class="'page-item'+(pagina == pagina_actual ? ' active' : '')"><a href="#" class="page-link" @click.prevent="pagina_actual=pagina">{{ pagina }}</a></li>
              <li :class="'page-item'+(pagina_actual >= total_paginas ? ' disabled' : '')"><a href="#" class="page-link" @click.prevent="pagina_actual++" :disabled="pagina_actual >= total_paginas">Siguiente</a></li>
              <li v-if="pagina_actual < (total_paginas - visualizar_paginas)" class="page-item"><a href="#" class="page-link" @click.prevent="pagina_actual=total_paginas">Final</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div v-for="observacion in paginacion" class="bloque_observacion">
        <div class="observacion">
          <div class="titulo">Observación</div>
          <div class="contenido" v-html="observacion.observacion"></div>
        </div>
        <div class="info"><span>Nombre:</span> {{ observacion.nombre }} | <span>Fecha:</span> {{ $moment(observacion.created_at).format('ddd DD MMM YYYY HH:mm')}}</div>
      </div>

      <div class="row">
        <div class="col-sm-12 text-right">
          Total items: {{ observaciones.length }} | Total páginas: {{ total_paginas }}
          <div style="display: inline-block;">
            <ul class="pagination justify-content-end">
              <li v-if="pagina_actual > visualizar_paginas" class="page-item"><a href="#" class="page-link" @click.prevent="pagina_actual=1">Inicio</a></li>
              <li :class="'page-item'+(pagina_actual <= 1 ? ' disabled' : '')"><a href="#" class="page-link" @click.prevent="pagina_actual--" :disabled="pagina_actual <= 1">Anterior</a></li>
              <li v-for="pagina in paginas" :class="'page-item'+(pagina == pagina_actual ? ' active' : '')"><a href="#" class="page-link" @click.prevent="pagina_actual=pagina">{{ pagina }}</a></li>
              <li :class="'page-item'+(pagina_actual >= total_paginas ? ' disabled' : '')"><a href="#" class="page-link" @click.prevent="pagina_actual++" :disabled="pagina_actual >= total_paginas">Siguiente</a></li>
              <li v-if="pagina_actual < (total_paginas - visualizar_paginas)" class="page-item"><a href="#" class="page-link" @click.prevent="pagina_actual=total_paginas">Final</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  watch: {
  },
  props: {
    solicitud: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      por_pagina:10,
      pagina_actual: 1,
      visualizar_paginas: 3
    }
  },
  computed: {
    observaciones() {
      return this.solicitud.data.formalizacion.observaciones;
    },
    total_paginas() {
      return Math.ceil(this.observaciones.length / this.por_pagina);
    }
    ,paginacion() {
      let inicio = this.pagina_actual == 1 ? 0 : (this.pagina_actual - 1) * this.por_pagina;
      let final = this.pagina_actual == this.total_paginas ? inicio + (this.observaciones.length - inicio) : inicio + this.por_pagina;

      let items = [];
      for(let i=inicio; i<final; i++) {
        items.push(this.observaciones[i]);
      }

      return items;
    },
    paginas() {
      let inicio = 1;

      if ((this.pagina_actual - this.visualizar_paginas) > 0) 
        inicio = (this.pagina_actual - this.visualizar_paginas);

      let final = (this.pagina_actual + this.visualizar_paginas) < this.total_paginas ? (this.pagina_actual + this.visualizar_paginas) : this.total_paginas;
      
      let items = [];

      for(let i=inicio; i<=final; i++) {
        items.push(i);
      }

      return items;
    }
  }
}
</script>

<style lang="scss">
.bloque_observacion {
  border: solid 1px grey;
  border-radius: 3px;
  margin-bottom: 10px;

  .observacion {
    border-bottom: solid 1px hsl(0, 0%, 80%);

    .titulo, .contenido {
      padding: 10px 15px;
      display: inline-block;
    }

    .titulo {
      background-color: hsl(0, 0%, 90%);
      width: 120px;
      font-weight: bold;
      vertical-align: top;
    }
  }

  .info {
    background-color: hsl(0, 0%, 90%);
    padding: 10px 15px;

    span {
      font-weight: bold;
    }
  }
}
</style>