import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_ANALYTICPOINT+'/solicitudes');

export default {
  obtener_solicitud(id) {
    id += (id.indexOf('?') >=0) ? '&relations[0]=documentos&relations[1]=bitacora' : '?relations[0]=documentos&relations[1]=bitacora';

    return http.get(`/${id}`);
  },

  enviar_voto(solicitud_id, payload) {
    return http.post(`/${solicitud_id}/votos`, payload);
  },

  eliminar_voto(solicitud_id, voto) {
    return http.delete(`/${solicitud_id}/votos/${voto}`);
  },

  actualizar_solicitud(solicitud_id, payload) {
    return http.put(`/${solicitud_id}`, payload);
  },

  reiniciar_solicitud(solicitud_id) {
    return http.put(`/restart/${solicitud_id}`);
  },
  
  obtener_url_temporal(solicitud_id, documento_id) {
    return http.get(`/${solicitud_id}/documentos/${documento_id}?link=1`);
  },

  descargar_minuta(solicitud_id,documento_id) {
    return http.get(`/${solicitud_id}/documentos/${documento_id}`,{responseType: 'blob'});
  },

  generar_minuta(solicitud_id) {
    return http.get(`/${solicitud_id}/minuta/decision`);
  },

  resolucion_vieja(solicitud_id) {
    return http.put(`/${solicitud_id}/resolucion/anterior`);
  },

  exportar_solicitud(solicitud_id) {
    return http.get(`/${solicitud_id}/exportar`,{responseType: 'blob'});
  }
}
