<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-lg-2">
        <ul class="nav flex-column nav-pills mt-4">
          <li class="nav-item"><a href="#" :class="'nav-link'+(tab_activo == 'resumen' ? ' active' : '')" @click.prevent="tab_activo='resumen'">Resumen modelo</a></li>
          <li class="nav-item"><a href="#" :class="'nav-link'+(tab_activo == 'garantia' ? ' active' : '')" @click.prevent="tab_activo='garantia'">Garantía</a></li>
          <li class="nav-item"><a href="#" :class="'nav-link'+(tab_activo == 'comportamiento' ? ' active' : '')" @click.prevent="tab_activo='comportamiento'">Comportamiento</a></li>
          <li class="nav-item"><a href="#" :class="'nav-link'+(tab_activo == 'capacidad' ? ' active' : '')" @click.prevent="tab_activo='capacidad'">Capacidad de pago</a></li>
          <li class="nav-item"><a href="#" :class="'nav-link'+(tab_activo == 'decision' ? ' active' : '')" @click.prevent="tab_activo='decision'">Decisión modelo</a></li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-10">
        <Resumen v-if="tab_activo == 'resumen'" :solicitud="solicitud" />
        <Garantia v-if="tab_activo == 'garantia'" :solicitud="solicitud" />
        <Comportamiento v-if="tab_activo == 'comportamiento'" :solicitud="solicitud" />
        <Capacidad v-if="tab_activo == 'capacidad'" :solicitud="solicitud" />
        <Decision v-if="tab_activo == 'decision'" :solicitud="solicitud" />
      </div>
    </div>
  </div>
</template>

<script>
import Resumen from './Resumen';
import Garantia from './Garantia';
import Comportamiento from './Comportamiento';
import Capacidad from './Capacidad';
import Decision from './Decision';

export default {
  components: {
    Resumen, Garantia, Comportamiento, Capacidad, Decision
  },
  props: {
    solicitud: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      tab_activo: 'resumen'
    }
  }
}
</script>