<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-center">Capacidad de pago</h1>
    </div>
    <div class="row">
      <h3 class="col-sm-12 text-center">Solicitud: {{ solicitud.solicitud_id }}</h3>
    </div>
    <div class="row">
      <h5 class="col-sm-12 text-center">Tipo solicitud: {{ solicitud.data.comite.general.tipo_solicitud }}</h5>
    </div>

    <div class="row">
      <h3 class="col-sm-12 text-center">Depósitos</h3>
      <div id="chartDepositos" class="col-sm-12"></div>
    </div>

    <div class="row my-3">
      <div class="col-sm-12 bloque_titulo">Resumen cuentas</div>
    </div>

    <div class="row item">
      <div class="col-sm-4 titulo">Monto solicitado</div>
      <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.ServiciosOriginacion.importe_solicitado) }}</div>
    </div>
    <div class="row item">
      <div class="col-sm-4 titulo">Pago requerido</div>
      <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.variablesUnykoo.BpbDeudaMensual) }}</div>
    </div>
    <div class="row item">
      <div class="col-sm-4 titulo">Total monto propuesto</div>
      <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.Ocr.montoPropuestoTotal) }}</div>
    </div>
    <div class="row item">
      <div class="col-sm-4 titulo">Ingresos totales promedio</div>
      <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.Ocr.ingresosTotal) }}</div>
    </div>

    <div class="row my-3">
      <div class="col-sm-12 bloque_titulo">Cuenta - 1</div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-6">
        <div class="row item">
          <div class="col-sm-4 titulo">Balance diario promedio</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.average_daily_balance) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Balance final (última vez)</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.ending_balance) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Monto de NSF's</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.ending_balance) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Número de días negativos</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.number_of_negative_days) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Ticket promedio (operación)</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.transaction_ticket_size) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Volatilidad monto opraciones</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.transaction_size_volatility) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Número de operaciones</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.number_of_transactions) }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Monto solicitado</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.ServiciosOriginacion.importe_solicitado) }}</div>
        </div>
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Pago requerido</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.variablesUnykoo.BpbDeudaMensual) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Ingresos</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.income) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Máximo</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.maximum_deposit) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Mínimo</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.minimum_deposit) }}</div>
        </div>
        <hr>
        <div class="row item">
          <div class="col-sm-4 titulo">Desv. Est. Calificación</div>
          <div class="col-sm-8 contenido">{{ ocr.transaction_size_volatility ? ocr.transaction_size_volatility : 0 }}%</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Pago posible</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.pagoPosible) }}</div>
        </div>
        <div class="row item">
          <div class="col-sm-4 titulo">Monto propuesto</div>
          <div class="col-sm-8 contenido">${{ $helper.moneyFormat(ocr.montoPropuesto) }}</div>
        </div>
        <div class="row item bloque_verde">
          <div class="col-sm-4 titulo">Payment to income</div>
          <div class="col-sm-8 contenido">{{ (ocr.pagoIngresos ? ocr.pagoIngresos : 0) * 100 }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gchart from '@/helpers/gcharts'

export default {
  props: {
    solicitud: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {

    }
  },
  mounted() {
    this.chartData();
  },
  methods: {
    async chartData() {
      await gchart();
      google.charts.load('current', {packages:['corechart']});
      google.charts.setOnLoadCallback(() => {
        let chart = new google.visualization.DataTable();
        chart.addColumn('string', 'Fechas');
        chart.addColumn('number', 'Depósitos');

        let chartRows = [];
        let numItems = this.solicitud.data.comite.raw.Running_Daily_Balance ? this.solicitud.data.comite.raw.Running_Daily_Balance.Date.length : 0;
        for(let i=0; i<numItems; i++) {
          chartRows.push([this.solicitud.data.comite.raw.Running_Daily_Balance.Date[i], (this.solicitud.data.comite.raw.Running_Daily_Balance.Total[i])]);
        }

        chartRows.sort(function(a,b) {
          let fechaA = a[0].split('/');
          let fechaB = b[0].split('/');

          return new Date(fechaA[2], (fechaA[0]-1), fechaA[1]) - new Date(fechaB[2], (fechaB[0]-1), fechaB[1]);
        });

        chart.addRows(chartRows);

        let options = {
          hAxis: {
            titule: 'Montos'
          },
          vAxis: {
            title: 'Fecha',
            minValue: 0
          }
        };

        let objChart = new google.visualization.AreaChart(document.getElementById('chartDepositos'));
        objChart.draw(chart, options)
      })
    }
  },
  computed: {
    ocr() {
      return this.solicitud.data.comite.general.Ocr;
    }
  }
}
</script>

<style lang="scss" scoped>
.bloque_titulo {
  background-color: #0781cd;
  color: #fefefe;
  font-size: 1.5rem;
  margin-top: 20px;
  padding: 2% 0 2% 6%;
}

.bloque_verde {
  background-color: #24b361 !important;
  color: #fff;
}
.item {
  .titulo, .contenido {
    padding: 10px 15px;
  }

  .contenido {
    text-align: right;
  }
}

.item:nth-child(2n+0) {
  background-color: #e6e6e6;
}
</style>