<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-center">Resumen del Modelo</h1>
    </div>
    <div class="row">
      <h3 class="col-sm-12 text-center">Solicitud: {{ solicitud.solicitud_id }}</h3>
    </div>
    <div class="row">
      <h5 class="col-sm-12 text-center">Tipo solicitud: {{ solicitud.data.comite.general.tipo_solicitud }}</h5>
    </div>

    <div class="content">
      <div class="row">
        <div class="col-sm-12 bloque_titulo">Descripción del Negocio</div>
      </div>

      <div class="row item">
        <div class="col-sm-3 titulo">¿Es empleado?</div>
        <div class="col-sm-9 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.es_empleado ? (solicitud.data.comite.general.ServiciosOriginacion.es_empleado == 1 ? 'Si' : 'No') : 'Sin información' }}</div>
      </div>
      <div class="row item">
        <div class="col-sm-3 titulo">Giro del negocio:</div>
        <div class="col-sm-9 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.giro_negocio.segundo_nivel }}</div>
      </div>
      <div class="row item">
        <div class="col-sm-3 titulo">Descripción del negocio:</div>
        <div class="col-sm-9 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.descripcion_giro_negocio }}</div>
      </div>

      <div class="row">
        <div class="col-sm-12 bloque_titulo">Política</div>
      </div>

      <div class="row item">
        <div class="col-sm-3 titulo">¿Cumple políticas?</div>
        <div class="col-sm-9 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.cumple_politicas ? (solicitud.data.comite.general.ServiciosOriginacion.cumple_politicas == 1 ? 'Si' : 'No') : 'Sin información' }}</div>
      </div>
      <div class="row item">
        <div class="col-sm-3 titulo">Descripción políticias</div>
        <div class="col-sm-9 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.descripcion_politicas }}</div>
      </div>
      <div class="row item">
        <div class="col-sm-3 titulo">Observaciones Comercial</div>
        <div class="col-sm-9 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.observaciones_comercial }}</div>
      </div>
      <div class="row item">
        <div class="col-sm-3 titulo">Observación Originación</div>
        <div class="col-sm-9 contenido">{{ solicitud.data.comite.general.ServiciosOriginacion.observaciones_originacion }}</div>
      </div>
    </div>

    <div class="content mb-4">
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12 bloque_titulo">Colateral</div>
          </div>

          <div class="row item">
            <div class="col-sm-4 titulo">Entidad:</div>
            <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.garantias[0].estado }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Valor propiedad</div>
            <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.opinionValor.valor) }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Comercialización</div>
            <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.opinionValor.tiempo_comercializacion) }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Evualuacion sobre 5</div>
            <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.opinionValor.score }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Aforo</div>
            <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.variablesUnykoo.aforo }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Tasa propuesta</div>
            <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.variablesUnykoo.tasaPropuesta }}</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-12 bloque_titulo">Capacidad</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Monto solicitado</div>
            <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.ServiciosOriginacion.importe_solicitado) }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Pago requerido</div>
            <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.variablesUnykoo.BpbDeudaMensual) }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Ingreso</div>
            <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.Ocr.ingresosTotal) }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">Monto propuesto</div>
            <div class="col-sm-8 contenido">${{ $helper.moneyFormat(solicitud.data.comite.general.Ocr.montoPropuestoTotal) }}</div>
          </div>
          <div class="row item">
            <div class="col-sm-4 titulo">DTI</div>
            <div class="col-sm-8 contenido">{{ solicitud.data.comite.general.variablesUnykoo.calculoCapacidadPago }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.bloque_titulo {
  background-color: #0781cd;
  color: #fefefe;
  font-size: 1.5rem;
  margin-top: 20px;
  padding: 2% 0 2% 6%;
}
.item {
  .titulo, .contenido {
    padding: 10px 15px;
  }

  .contenido {
    text-align: right;
  }
}

.item:nth-child(2n+1) {
  background-color: #e6e6e6;
}
</style>