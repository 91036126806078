<template>
  <div>
    <h2>Garantías</h2>

    <ul class="nav nav-tabs justify-content-end mb-3">
      <li v-for="garantia in solicitud.solicitud.garantias_originacion" class="nav-item">
        <a :class="'nav-link'+(garantia.id == garantia_ver ? ' active' : '')" href="#" :title="'Garantía '+garantia.id" @click.prevent="cambiar_mapa(garantia.id)">Garantía: {{ garantia.calle }}</a>
      </li>
    </ul>

    <template v-if="!garantia_seleccionada">
      Cargando información...
    </template>
    <template v-else>
    <div class="row my-3 info_garantias">
      <div class="col-sm-12 col-lg-5 offset-lg-1 info-col">
        <ul>
          <li><span class="info-row-title">Colateral:</span> <span class="info-row-data">{{ op.colateral }}</span></li>
          <li><span class="info-row-title">Antigüedad en años:</span> <span class="info-row-data">{{ parseFloat(op.antiguedad).toFixed(0) }}</span></li>
          <li><span class="info-row-title">Calle:</span> <span class="info-row-data">{{ garantia_seleccionada.calle }}</span></li>
          <li><span class="info-row-title">Número exterior:</span> <span class="info-row-data">{{ garantia_seleccionada.numero_ext }}</span></li>
          <li><span class="info-row-title">Colonia:</span> <span class="info-row-data">{{ garantia_seleccionada.colonia }}</span></li>
          <li><span class="info-row-title">Municipio:</span> <span class="info-row-data">{{ garantia_seleccionada.municipio }}</span></li>
          <li><span class="info-row-title">Ciudad:</span> <span class="info-row-data">{{ garantia_seleccionada.ciudad }}</span></li>
          <li><span class="info-row-title">Estado:</span> <span class="info-row-data">{{ garantia_seleccionada.entidad ? garantia_seleccionada.entidad.nombre : garantia_seleccionada.estado }}</span></li>
          <li><span class="info-row-title">Código postal</span> <span class="info-row-data">{{ garantia_seleccionada.cp }}</span></li>
        </ul>
      </div>
      <div class="col-sm-12 col-lg-5">
        <ul>
          <li><span class="info-row-title">Situación:</span> <span class="info-row-data">{{ gravament }}</span></li>
          <li><span class="info-row-title">Tipo propiedad:</span> <span class="info-row-data">{{ garantia_seleccionada.tipo_garantia.nombre }}</span></li>
          <li><span class="info-row-title">Propietario:</span> <span class="info-row-data">{{ propietario }}</span></li>
          <li><span class="info-row-title">Tipo propiedad:</span> <span class="info-row-data">{{ garantia_seleccionada.tipo_garantia.nombre }}</span></li>
          <li><span class="info-row-title">Valor propiedad:</span> <span class="info-row-data">${{ $helper.moneyFormat(op.valor) }}</span></li>
          <li><span class="info-row-title">Comercialización:</span> <span class="info-row-data">{{ op.tiempo_comercializacion }}</span></li>
          <li><span class="info-row-title">Estado de conservación:</span> <span class="info-row-data">{{ op.estado_conservacion }}</span></li>
          <li><span class="info-row-title">Evaluación (sobre 5):</span> <span class="info-row-data">{{ parseFloat(op.score).toFixed(1) }}</span></li>
        </ul>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12 col-lg-6 imagenes_garantias">
        <img :src="'https://maps.googleapis.com/maps/api/streetview?size=500x400&location='+garantia_seleccionada.latitud+','+garantia_seleccionada.longitud+'&heading='+garantia_seleccionada.heading+'&pitch='+garantia_seleccionada.pitch+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4'" alt="" />
      </div>
      <div class="col-sm-12 col-lg-6 imagenes_garantias">
        <img :src="'https://maps.googleapis.com/maps/api/staticmap?size=500x400&markers='+garantia_seleccionada.latitud+','+garantia_seleccionada.longitud+'&key=AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4&scale=1'" alt="" />
      </div>
      <div class="col-sm-12 col-lg-6 imagenes_garantias">
        <img v-if="url_fachada" :src="url_fachada" alt="" />
      </div>
      <div class="col-sm-12 col-lg-6 map_google">
        <div class="map_google" :id="'map'+garantia_seleccionada.id"></div>
      </div>
    </div>
    </template>
  </div>
</template>

<script>
import api from '@/apps/analyticpoint/api/comite/solicitudes';

import gmaps from '@/helpers/gmaps'

export default {
  props: {
    solicitud: {
      type: Object,
      default() {
        return {
          solicitud_id: null,
          solicitud: {
            es_empleado: 0,
            giro_negocio: {
              primer_nivel: '',
              segundo_nivel: ''
            },
            op_variables: {
              valor: 0,
              score: 0,
              tiempo_comercializacion: ''
            },
            garantias_originacion: [
              {
                estado: '',
                tipo_garantia:{
                  nombre: ''
                }
              }
            ],
          },
        }
      }
    }
  },
  data() {
    return {
      garantia_ver: 0,
      url_fachada: null
    }
  },
  async mounted() {
    const google = await gmaps(); 
    this.garantia_ver = this.solicitud.solicitud.garantias_originacion[0].id;
    setTimeout(this.cargar_mapa, 500);
  },
  methods: {
    cambiar_mapa(id) {
      this.garantia_ver = id;
      setTimeout(this.cargar_mapa, 500);
    },
    cargar_mapa() {
      this.$log.info('garantia:')
      
      let latitud = parseFloat(this.garantia_seleccionada.latitud);
      let longitud = parseFloat(this.garantia_seleccionada.longitud);

      let mapa = new google.maps.Map(document.getElementById('map'+this.garantia_seleccionada.id), {
        center: {
          lat: latitud,
          lng: longitud
        },
        zoom: 17
      });

      new google.maps.Marker({
        position: new google.maps.LatLng(latitud, longitud),
        map: mapa,
        title: 'Garantía'
      })
    },
  },
  computed: {
    garantia_seleccionada() {
      let garantia = null;
      let garantias = this.solicitud.solicitud.garantias_originacion;

      for(let i=0; i<garantias.length; i++) {
        if (garantias[i].id == this.garantia_ver)
          garantia = garantias[i];
      }

      return garantia;
    },
    op() {
      let op = {
        colateral: 0
      };

      if (this.garantia_seleccionada && this.garantia_seleccionada.op)
        return this.garantia_seleccionada.op;

      if (this.garantia_seleccionada && this.garantia_seleccionada.op_ai360.length > 0)
        op = this.garantia_seleccionada.op_ai360[this.garantia_seleccionada.op_ai360.length-1];

      if (this.garantia_seleccionada && this.garantia_seleccionada.op_rasa.length > 0)
        op = this.garantia_seleccionada.op_rasa[this.garantia_seleccionada.op_rasa.length-1];

      this.$log.info('op', op)
      return op;
    },
    gravament() {
      if (!this.solicitud.solicitud.avatar)
        return 'Sin información';

      if(this.solicitud.solicitud.avatar.existencia_gravament)
        return 'Tiene gravamen';

      return 'No tiene gravamen';      
    },
    propietario() {
      if (this.solicitud.solicitud.avatar && this.solicitud.solicitud.avatar.dueno_colateral != null)
        return this.solicitud.solicitud.avatar.dueno_colateral;

      if (this.garantia_seleccionada.propia)
        return 'Propia';

      return 'No es propia';
    },
    // async url_fachada() {
    //   let garantia_id = this.garantia_seleccionada.fachada_id;
    //   return (await api.obtener_url_temporal(this.solicitud.id, garantia_id)).data;
    // }
  },
  watch: {
    async garantia_seleccionada(val, old_val) {
      let garantia_id = this.garantia_seleccionada.fachada_db.id;
      this.url_fachada = (await api.obtener_url_temporal(this.solicitud.id, garantia_id)).data;
    }
  }
}
</script>

<style lang="scss" scoped>
  .info_garantias {
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        padding: 5px 10px;

        span {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        }

        .info-row-title {
          width: 43%;
          display: inline-block;
        }

        .info-row-data {
          width: 55%;
          text-align: right;
          display: inline-block;
        }
      }

      li:nth-child(2n+1) {
        background-color: #ececec;
      }
    }
  }

  .imagenes_garantias {
    text-align: center;

    img {
      max-width: 500px;;
    }
  }

  .map_google {
    width: 500px !important;
    height: 400px;
    margin:auto
  }
</style>