<template>
  <div>
    <Modal :options="{width: '40vw',close: true,type:'secondary'}" @close="$emit('close')">
      <div class="title">Votos</div>
      <div class="body">
        <div v-if="solicitud.votos.length == 0">No hay votos</div>
        <template v-else>
        <div v-for="voto in solicitud.votos" :class="'voto'+tipo_voto(voto)">
          <div class="acreditador">
            <div class="nombre">{{ voto.acreditador.nombre }}</div>
            <div class="fecha">{{ $moment(voto.created_at).format('dd DD MMM YYYY') }}</div>
          </div>
          <div class="info_voto">
            <div class="resolucion">Resolución: <span class="resolucion">{{ voto.resolucion }}</span></div>
            <div class="monto">Monto: ${{ $helper.moneyFormat(voto.monto) }}</div>
            <div class="tasa">Tasa: {{ voto.tasa_interes }}%</div>
            <div class="razon">Razón: {{ voto.razon }}</div>
            <div v-if="voto.observaciones" class="observaciones">{{ voto.observaciones }}</div>
          </div>
        </div>
        </template>
      </div>
    </Modal>

    <Confirmar v-if="confirmar_borrado" :config="confirmacion_config" />
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import Confirmar from './Otros/Modales/Confirmar.vue';

import api from '@/apps/analyticpoint/api/comite/solicitudes';
import apiCatalogo from '@/apps/analyticpoint/api/comite/catalogos';

export default {
  components: {
    Modal,Confirmar
  },
  props: {
    solicitud: {
      type: Object,
      default() {
        return {
          solicitud: {},
          votos: []
        }
      }
    }
  },
  data() {
    return {
      catalogo: {
        opciones: []
      },
      confirmar_borrado: false,
      voto_borrar: null,
      confirmacion_config: {
        title: 'Borrar comentario',
        body: '<p class="text-center">¿Realmente deseas borrar el voto indicado?<br/>Esta acción no se puede deshacer</p>',
        button_accept: 'Aceptar',
        button_reject: 'Cancelar',
        accept_callback: null,
        reject_callback: null
      }
    }
  },
  mounted() {
    this.cargar_catalogo();
    this.confirmacion_config.accept_callback = this.eliminar_voto;
    this.confirmacion_config.reject_callback = this.cancelar_borrado;
  },
  methods: {
    async cargar_catalogo() {
      try {
        this.catalogo = (await apiCatalogo.buscar_catalogo('razones_rechazo')).data;
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    tipo_voto(voto) {
      return voto.resolucion == 'aceptado' ? ' voto_success' : ' voto_refused';
    },
    async eliminar_voto() {
      try {
        let res = (await api.eliminar_voto(this.solicitud.id, this.voto_borrar)).data;
        this.$log.info('res', res);
        this.cancelar_borrado();
        this.$emit('update', true);
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    preguntar_borrado(id) {
      this.confirmar_borrado = true;
      this.voto_borrar = id;
    },
    cancelar_borrado() {
      this.confirmar_borrado = false;
      this.voto_borrar = null;
    }
  }
}
</script>

<style lang="scss">
$solicitud_votada: #c0e9c7;
$solicitud_rechazada: #e2bcbc;
$porcentaje_dark: 30%;

.voto {
  padding: 3px;
  margin-bottom: 10px;
  border: solid 1px hsl(0, 0%, 84%);

  .acreditador {
    background-color: hsl(0, 0%, 93%);

    .nombre,.fecha {
      width: 68%;
      padding: 5px 8px;
      display: inline-block;
    }

    .fecha {
      width: 30%;
      text-align: right;
    }
  }

  .info_voto {
    
    div.resolucion,.monto,.tasa {
      width: 33%;
      padding: 3px 5px;
      display: inline-block;
    }
    
    div.resolucion {
      span.resolucion {
        width: 50%;
        font-weight: bold;
      }
    }

    .comentario {
      padding: 3px 5px;
      border-top: solid 1px hsl(0, 0%, 84%);
    }
  }
}
.voto_success {
  .acreditador {
    background-color: $solicitud_votada;
  }

  .info_voto {
    div.resolucion {
      span.resolucion {
        color: darken($solicitud_votada, $porcentaje_dark);
      }
    }
  }
}

.voto_refused {
  .acreditador {
    background-color: $solicitud_rechazada;
  }

  .info_voto {
    div.resolucion {
      span.resolucion {
        color: darken($solicitud_rechazada, $porcentaje_dark);
      }
    }
  }
}
</style>