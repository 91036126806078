<template>
  <div>
    <div class="row my-3">
      <h3 class="col-sm-12">Documentos</h3>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div v-for="documento in documentos" class="col-sm-3 mb-3">
          <div class="card">
            <div class="card-header">
              {{ documento.nombre_original}}
            </div>
            <div class="card-body">
              <h5 class="card-title">Tipo: {{ documento.tipo_documento }}</h5>
              <div class="card-text">
                <ul>
                  <li>Tipo: {{ documento.extension }}</li>
                  <li>Peso: {{ parseFloat((parseInt(documento.peso) / 1000) / 1000).toFixed(2) }}mb</li>
                  <li>Versión: {{ documento.version }}</li>
                </ul>
              </div>
              <div class="text-right">
                <button class="btn btn-primary mr-2" @click="descargar_documento(documento)">Descargar</button>
                <button class="btn btn-secondary" @click="preview_documento=documento">Preview</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Preview v-if="preview_documento" :documento="preview_documento" @close="preview_documento=null" />
  </div>
</template>

<script>
import Preview from './Preview.vue';

import api from '@/apps/fyc/api/otros'

export default {
  components: {
    Preview
  },
  props: {
    solicitud: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ver_preview: false,
      preview_documento: null
    }
  },
  methods: {
    async descargar_documento(archivo) {
      try {
        let file = (await api.descargar_documento(archivo.solicitud_id, archivo.id)).data;

        let url = window.URL.createObjectURL(new Blob([file]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',archivo.nombre_original);
        document.body.appendChild(link);
        link.click();
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  },
  computed: {
    documentos() {
      return this.solicitud.data.valija.documentos;
    }
  }
}
</script>