<template>
  <div>
    <div class="row">
      <h3 class="col-sm-12">Cliente</h3>
    </div>

    <div class="row my-3">
      <div class="col-sm-12">
        <div class="item"><div class="title">Nombre:</div><div class="content">{{ solicitud.data.creditos.nombre }}</div></div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="item"><div class="title">Teléfono:</div><div class="content">{{ solicitud.data.creditos.telefono }}</div></div>
        <div class="item"><div class="title">Celular:</div><div class="content">{{ solicitud.data.creditos.celular }}</div></div>
        <div class="item"><div class="title">E-mail:</div><div class="content">{{ solicitud.data.creditos.email }}</div></div>
        <div class="item"><div class="title">Método de contacto preferido:</div><div class="content">{{ solicitud.data.creditos.metodo_preferido_contacto }}</div></div>
        <div class="item"><div class="title">Médio de información:</div><div class="content">{{ solicitud.data.creditos.medio_informacion.canal+'('+solicitud.data.creditos.medio_informacion.sub_sub_canal+')' }}</div></div>
        <div class="item"><div class="title">Acepta políticas de privacidad:</div><div class="content">{{ solicitud.data.creditos.acepta_politicas_privacidad == 1 ? 'Sí' : 'No' }}</div></div>
        <div class="item"><div class="title">Ingreso mensual:</div><div class="content">${{ $helper.moneyFormat(solicitud.data.creditos.ingreso_mensual) }}</div></div>
        <div class="item"><div class="title">Monto solicitado:</div><div class="content">${{ $helper.moneyFormat(solicitud.data.creditos.importe_solicitado) }}</div></div>
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="item"><div class="title">Monto pre-autorizado:</div><div class="content">${{ $helper.moneyFormat(solicitud.data.creditos.importe_pre_autorizado) }}</div></div>
        <div class="item"><div class="title">Aceptó el importe pre-autorizado:</div><div class="content">{{ solicitud.data.creditos.acepto_importe_pre_autorizado == 1 ? 'Sí' : 'No' }}</div></div>
        <div class="item"><div class="title">Plazo:</div><div class="content">{{ solicitud.data.creditos.plazo * 12 }} meses</div></div>
        <div class="item"><div class="title">Descripción:</div><div class="content">{{ solicitud.data.creditos.descripcion_solicitud }}</div></div>
        <div class="item"><div class="title">Buró Score</div><div class="content"></div></div>
        <div class="item"><div class="title">OCR Ingresos</div><div class="content"></div></div>
        <div class="item"><div class="title">Clave CIEC:</div><div class="content">{{ solicitud.data.creditos.clave_ciec ? 'Agregada' : 'Sin agregar' }}</div></div>
      </div>
      <div class="col-sm-12">
        <div class="item"><div class="title">Referente:</div><div class="content">{{ solicitud.data.creditos.referente }}</div></div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-6">
        <div class="item">
          <div class="title">¿Es empleado?:</div>
          <div class="content">{{ solicitud.data.creditos.es_empleado == 1 ? 'Sí' : 'No' }}</div>
        </div>
        <div class="item">
          <div class="title">Gir del negocio:</div>
          <div class="content">{{ solicitud.data.creditos.segundo_nivel }}</div>
        </div>
        <div class="item">
          <div class="title">Descripción detallada:</div>
          <div class="content">{{ solicitud.data.creditosdescriopcion_giro_negocio }}</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="item">
          <div class="title">Cumple políticas:</div>
          <div class="content">{{ solicitud.data.creditos.cumple_politicas == 1 ? 'Sí' : 'No' }}</div>
        </div>
        <div class="item">
          <div class="title">Descripción políticas:</div>
          <div class="content">{{ solicitud.data.creditos.descripcion_politicas }}</div>
        </div>
        <div class="item">
          <div class="title">Observaciones comercial:</div>
          <div class="content">{{ solicitud.data.creditos.observaciones_comercial }}</div>
        </div>
        <div class="item">
          <div class="title">Observaciones analytic point:</div>
          <div class="content">{{ solicitud.data.creditos.observaciones_originacion }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    solicitud: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .item {
    margin-bottom: 5px;
    border: solid 1px hsl(0,0%, 90%);
    display: flex;
    align-items: center;

    .title {
      width: 170px;
      height: 100%;
      background-color: hsl(0, 0%, 90%);
      padding: 10px 15px;
      display: inline-block;
    }

    .content {
      width: calc(100% - 170px);
      height: 100%;
      background-color: #fff;
      display: inline-block;
    }
  }
</style>